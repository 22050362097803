.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  font-weight: 700;
  color: #3a3a3c;

  .dashboard-header {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px #c7c7cc solid;

    .dashboard-title {
      margin-top: 16px;
      margin-bottom: 16px;

      h1 {
        font-size: 24px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .time-filter-container {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
  }

  .admin-tab-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 157px);
    width: 100%;
    padding: 24px;
  }
}
