.dropdown-multiple {
  margin: 12px;
  border: 1px #e5e5ea solid;
  background-color: white;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 400px;
  max-width: 400px;
  box-shadow: 0px 3px 12px rgba(1, 12, 27, 0.1);
  border-radius: 16px;

  .dropdown-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    color: rgba(72, 72, 74, 1);
    padding: 0px 8px 0px 0px;

    button {
      margin-left: 20px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      font-size: 22px;

      &:active {
        transform: scale(0.95);
      }
    }
  }

  .dropdown-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }

  .dropdown-list-multiple {
    margin: 0;
    padding: 0;
    max-height: 170px;
    overflow-y: auto;

    .dropdown-item {
      padding: 12px 8px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      border-radius: 8px;

      &:hover {
        background-color: #f0f0f0;
        color: #35a7ff;
      }
      input {
        cursor: pointer;
        width: 20px;
        height: 20px;
        &:active {
          color: #35a7ff;
        }
      }

      .dropdown-label {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
      }
    }
  }

  .dropdown-buttons {
    display: flex;

    justify-content: space-between;
    gap: 16px;
  }
}
