.img-crop-background-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(58, 58, 60, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-y: auto;
  z-index: 3;
  pointer-events: none;

  .img-crop-form {
    background-color: #fff;
    border: 1px solid #e7e7e9;
    border-radius: 12px;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    .img-crop-form-header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5ea;
      color: #3a3a3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      .img-crop-cross-sign {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: white;
        width: 24px;
        height: 24px;
      }
    }

    .img-crop {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}
