.toggle-headline-component {
  .toggle-headline {
    display: flex;
    border-radius: 24px;
    border: 1px #8e8e93 solid;
    padding: 8px 10px;
    gap: 8px;
    height: 28px;
    font-size: 14px;
    align-items: center;

    &.news {
      font-size: 16px;
      height: 40px;
    }

    .toggle-headline-icon {
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;

      &.no-photo {
        width: 0px;
      }

      &.right {
        margin-left: auto;
      }
    }

    .toggle-headline-name {
      flex-grow: 1;
      text-align: center;
      font-weight: 500;
      line-height: 20px;
      word-wrap: break-word;
    }

    :hover {
      cursor: pointer;
    }
  }

  .dropdown-menu-playlist {
    position: relative;
  }
  &.disabled {
    .toggle-headline {
      padding: 2px;
      border: none;
      color: #636366;
      :hover {
        cursor: default;
      }
    }
  }
}
