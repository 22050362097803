@import "../../styles/variables.scss";

.sidebar-wrapper {
  display: flex;
  height: 100%;
  width: 100%;

  .sidebar-menu {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &.horizontal {
      flex-direction: row;
      justify-content: space-between;
    }

    &.vertical {
      justify-content: center;
      flex-direction: column;
      row-gap: 18px;
    }

    .label-option {
      color: #3a3a3c;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      row-gap: 4px;
    }

    .sidebar-button {
      min-height: 45px;
      min-width: 45px;
      padding: 4px;
      border: none;
      background-color: #f4f4f4;
      color: black;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      justify-self: center;
      flex-direction: column;
      transition: 0.2s ease-in;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: scale(0.95);
      }
      p {
        font-size: $description-font-size;
      }
    }

    .sidebar-button:hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.4s;
      }
    }

    .sidebar-button:not(:hover) .tooltip-text {
      transition-delay: 0.2s;
      opacity: 0;
      visibility: hidden;
    }

    .sidebar-text {
      margin: 0;
      font-size: 12px;
      text-align: center;
    }
    .btn-selected {
      background-color: #35a7ff;

      &:hover {
        background-color: #53b5ff;
      }
    }
  }
}
