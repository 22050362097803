//import variables
@import "../../styles/variables.scss";
.right-wrapper {
  width: calc(100vw - 350px);
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.video-add-wrapper-ai {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(58, 58, 60, 0.4);
  z-index: 10;
  cursor: auto;

  .ai-playlist-preview {
    width: 80vw;
    height: 80vh;
    background-color: #fff;
    border: 1px solid #e7e7e9;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .video-preview-body {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }

    .video-add-title {
      padding: 16px 24px 16px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5ea;
      color: #3a3a3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      height: 77px;

      .playlists-search {
        min-width: 336px;
      }
    }

    .video-bottom {
      margin-top: auto;
      padding: 16px 24px 16px 40px;
      display: flex;
      justify-content: end;
      gap: 8px;
      align-items: center;
      border-top: 1px solid #e5e5ea;
      color: #3a3a3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      height: 67px;

      .playlists-search {
        min-width: 336px;
      }
    }

    .video-add-cross-sign {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: white;
      width: 24px;
      height: 24px;
    }

    .add-content {
      display: flex;
      padding: 16px 40px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      height: calc(80vh - 144px);

      .playlist-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #3a3a3c;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      .video-add-tabs {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }
      .video-list {
        height: auto;
        padding-right: 20px;
        overflow: auto;
        margin-top: 24px;
        display: flex;
        gap: 15px;
      }

      .pagination {
        margin-top: 10px;
      }
    }
  }

  .video-preview-header {
    display: flex;
    padding: 16px 24px 16px 16px;
    min-height: 70px;
    max-height: 70px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-light-200, #e5e5ea);
    background: var(--Neutral-light-10, #fff);
  }

  .video-add-cross-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    width: 24px;
    height: 24px;
  }
  .return-button {
    display: flex;
    width: 143px;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: white;
    color: var(--Neutral-dark-800, #3a3a3c);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
