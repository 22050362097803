.video-image-container {
  display: flex;
  background-color: #636366;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  .card-thumbnail {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
      width: 20%;
    }

    .deleted-thumbnail {
      transform: scale(2);
    }

    color: #fff;
    text-align: center;
    font-size: 90%;
    font-weight: 600;
    line-height: 100%;
  }

  .video-image {
    background-color: #636366;
    width: 100%;
    height: 100%;
  }
}
