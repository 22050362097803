.animated-dropdown {
  -webkit-animation: dropdown 0.2s ease-in-out;
  animation: dropdown 0.2s ease-in-out;
}

.animate__close-dropdown {
  opacity: 0;
  -webkit-animation: close-dropdown 0.2s ease-in-out !important;
  animation: close-dropdown 0.2s ease-in-out !important;
}

@-webkit-keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes close-dropdown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes close-dropdown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
