.dropdown-my-account {
  position: absolute;
  z-index: 15;
  right: 0px;
  margin-top: 8px;
  padding: 16px 12px;
  box-shadow: 0px 12px 24px rgba(1, 12, 27, 0.1);
  border-radius: 16px;
  border: 1px #e5e5ea solid;
  background-color: white;
  overflow: auto;
  width: 260px;

  .dropdown-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;
    cursor: auto;

    .dropdown-list-item {
      display: flex;
      justify-content: space-between;
      padding: 12px 8px;
      align-items: center;
      cursor: pointer;
      align-items: left;
      border-radius: 8px;

      .dropdown-list-item-left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .dropdown-list-option-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;

          .dropdown-icon {
            width: 24px;
            height: 24px;
          }
        }

        .dropdown-list-option-name {
          line-height: 24px;
          color: #3a3a3c;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .dropdown-list-option-checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #35a7ff;
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    :hover {
      background-color: #ebf6ff;
    }
  }

  .divider {
    border-top: 1px #e7e7e9 solid;
    padding: 0;
    margin: 0;
  }
}
