@mixin button-base {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
}

.button-small {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
}

.button-medium {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
}

.no-borders {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.button-icon-component-basic-primary {
  @include button-base;
  background-color: #35a7ff;
  color: white;
}

.button-icon-component-basic-primary:hover {
  background-color: #79c4ff;
}

.button-icon-component-basic-secondary {
  @include button-base;
  color: #3a3a3c;
  background-color: white;
  border: 1px #636366 solid;
}

.button-icon-component-basic-secondary:hover {
  background-color: #f2f2f7;
}

.button-icon-component-basic-ghost {
  @include button-base;
  background-color: white;
  color: #35a7ff;
}

.button-icon-component-basic-ghost:hover {
  background-color: #f4f4f4;
}

.button-icon-component-basic-disable {
  @include button-base;
  background-color: #aeaeb2;
  color: #f2f2f7;
  cursor: default;
}

.button-icon-component-basic-disable:hover {
  background-color: #aeaeb2;
}

.pink {
  background-color: #ff3559;
  color: white;
}

.pink:hover {
  background-color: red;
}
