.edit-card-page {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 76px);
  width: 100%;
  overflow: hidden;

  .edit-card-fields {
    flex-grow: 1;
    border-right: 1px #eaeaec solid;
    height: auto;
    display: flex;
    flex-direction: column;
    min-width: 300px;

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-left: auto;
      padding: 16px;
      border-top: 1px #eaeaec solid;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .edit-card-preview-and-colors {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 32px;
    height: 100%;

    .edit-card-preview {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      flex-shrink: 0;

      .title {
        color: #3a3a3c;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
      }

      .edit-preview {
        max-height: calc(100% - 50px);
        display: flex;
        width: calc(100% - 60px);
        width: calc(100% - 60px);
        height: 100%;
      }
    }

    .colors {
      margin-top: 48px;
    }
  }
}
