.settings {
  display: flex;
  flex-direction: column;
  height: 100%;

  .headline {
    padding: 24px min(240px, calc(50% - 150px));
    color: #3a3a3c;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .tab {
    padding: 8px min(240px, calc(50% - 150px)) 0px;
    border-bottom: 1px #c7c7cc solid;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 40px min(240px, calc(50% - 150px));
  }

  .footer {
    width: 100%;
    padding: 12px min(240px, calc(50% - 150px));
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 12px;
    margin-top: auto;
    border-top: 1px #e5e5ea solid;
    height: 72px;

    .saving-spinner {
      width: 48px;
      height: 48px;
    }
    .button {
      width: 94px;
    }
  }
}
