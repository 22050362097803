body {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  overflow-y: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 768px) and (hover: none) and (pointer: coarse) {
  .main {
    padding-bottom: 0px;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.main {
  flex: 1;
  width: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 10px;
  background-color: #e7e7e9;
  background-clip: content-box;
  cursor: pointer;
  &:hover {
    background-color: #a8a8a8;
    border-width: 3.5px;
  }
}

.hidden-scrollbar {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Animations to fade the SIMPLE-TOAST in and out */
@-webkit-keyframes slide-fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 24px;
    opacity: 1;
  }
}

@keyframes slide-fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 24px;
    opacity: 1;
  }
}

@-webkit-keyframes slide-fadeout {
  from {
    bottom: 24px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes slide-fadeout {
  from {
    bottom: 24px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.rounded-full {
  border-radius: 50px;
}

h4 {
  color: #3a3a3c;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

caption {
  font-size: 14px;
  color: #636366;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #636366;
  margin: 0;
  padding: 0;
}

h5 {
  color: #3a3a3c;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.h7 {
  color: #3a3a3c;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.h8 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #48484a;
  margin: 0;
  padding: 0;
}

input {
  background: none;
}

button {
  &:focus {
    outline: none;
  }
}

.font-bold {
  font-weight: bold;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}
