.spinner-container-feed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 74px);
  width: 100vw;
}

.feed-container {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 100px;
  background-color: white;
  height: calc(100vh - 74px);
  min-height: calc(100vh - 74px);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  width: 100vw;

  .feed-container-card {
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: calc(100vh - 74px - 24px);
    height: calc(100vh - 74px - 24px);
    max-height: calc(100vh - 74px - 24px);
    scroll-snap-align: start;
    scroll-snap-stop: always;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .feed-container-card-end {
    min-height: 0px;
    height: 0px;
  }

  &.my-tab {
    height: calc(100vh - 74px - 107px);
    min-height: calc(100vh - 74px - 107px);
    width: calc(100vw - 380px);
    top: calc(74px + 107px);
    left: 360px;

    .feed-container-card {
      min-height: calc(100vh - 74px - 107px);
      height: calc(100vh - 74px - 24px - 107px);
      max-height: calc(100vh - 74px - 24px - 107px);
    }
  }

  &.video-preview {
    width: 100%;
    min-height: 100%;
    max-height: 100%;

    .feed-container-card {
      min-height: 100%;
      max-height: 100%;
    }
  }

  &.focus-mode {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 3;

    .feed-container-card {
      height: calc(100vh);
      min-height: calc(100vh);
      margin-top: 0px;
      padding-top: 24px;
    }
  }

  &.one-card {
    padding-bottom: 0px;
  }
}

.feed-navigation {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  min-height: 76px;
  animation: slideOutFromRight 0.3s ease-out;

  .chevron-container {
    min-width: 40px;
    min-height: 40px;

    button {
      display: flex;
      flex-direction: column;
      background-color: Neutral light/50;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      justify-self: center;
      align-self: center;
      rotate: 0;

      padding: 6px;

      &:hover {
        background-color: rgb(230, 230, 230);
      }
    }

    #chevron-up-up {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.show-double-up #chevron-up-up {
      opacity: 1;
    }
  }

  &.focus-mode {
    right: calc(504px + 24px);
  }

  &.desktop {
    right: calc(505px + 24px);
  }
}
