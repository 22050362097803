.preview-wrapper {
  height: 100%;
  border-left: 1px solid var(--Neutral-light-200, #e5e5ea);
  width: 100%;
  display: flex;
  max-width: 360px;
  padding: 24px 18px;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  .preview-top {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .preview-header {
    color: var(--Neutral-dark-800, #3a3a3c);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  .add-btn {
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: var(--Primary-100, #35a7ff);
    border: none;
    cursor: pointer;
    &:hover {
      background: var(--Primary-200, #79c4ff);
    }
  }
  .btn-text {
    color: var(--Neutral-light-10, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .preview-deck {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow: auto;
    height: 100%;
    padding-bottom: 25px;
  }
}
