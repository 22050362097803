// _variables.scss

// Colors
$video-placeholder-background-color: #ccc;
$card-background-color: #fff;
$text-color: #333;
$title-color: $text-color;
$highlighted-text-color: #34aff7;
$main-logo-color: #393939;
$subscriber-count-color: #fff;
$subscriber-count-background-color: #34aff7; // Or any color that matches your design
$footer-color: #666;
$tag-background-color: #eeeeee;
$tag-text-color: #0d8eec;
$button-dark-color: #333333;
$border-color: #c4c4c4;
$navbar-border-color: #e5e5ea;
$shaded-background-color: rgb(219, 219, 219);

// Typography

$tag-font: "inter";
$tag-line-height: 24px;
$tag-letter-spacing: -1%;
$tag-font-size: 16px;
$main-logo-font-size: 24px;
$main-logo-font-weight: bold;

// Spacing and layout
$card-margin: 20px auto;
$card-width: 500px; // Adjust to match the width in your design
$card-content-padding: 20px;
$title-margin-bottom: 10px;
$description-margin-bottom: 10px;
$tags-margin-bottom: 15px;
$author-info-margin-bottom: 15px;
$tag-padding: 5px 10px;
$tag-margin-right: 5px;
$tag-border-radius: 16px;
$content-area-padding: 40px;
$content-area-padding-mobile: 20px;

// Typography
$font-family-base: "Arial", sans-serif;
$title-font-size: 20px;
$title-font-weight: bold;
$description-font-size: 14px;
$footer-font-size: 12px;

// Author Info
$author-name-font-weight: bold;
$subscriber-count-font-size: 12px;
$subscriber-count-padding: 5px 10px;
$subscriber-count-border-radius: 15px;

// Card
$card-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$card-border-radius: 8px;
$button-border-radius: 20px;

// Responsive Breakpoints
$mobile-breakpoint: 768px;

// ... add more variables as needed for your design
