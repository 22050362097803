@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@import "../../styles/variables.scss";

.video-card {
  display: flex;
  width: calc(min(calc(100vh - 100px), 100vw / 1.4, 1200px) * 1.4);
  height: calc(min(calc(100vh - 100px), 100vw / 1.4, 1200px));

  &.mobile {
    height: min(calc(100vh - 200px), calc(100vw - 48px) * 16 / 9);
  }

  .left-video-part {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 100%;

    .cancel {
      position: fixed;
      top: 24px;
      left: 24px;

      button {
        background-color: Neutral light/50;
        border: none;
        border-radius: 50%;
        aspect-ratio: 1;
        transform: scale(1.1);
        padding: 6;

        &:hover {
          background-color: rgb(230, 230, 230);
        }
      }
    }

    .landscape-sidebar-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100% !important;
      max-width: 2000px !important;
      max-height: 1125px !important;
      justify-content: center;
      margin-top: 36px;
      padding: 0px 40px;

      .landscape-video-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
        overflow: hidden;
        padding: 0px 40px;
        gap: 24px;

        .landscape-video-top {
          width: 100%;
        }

        &.landscape-my-tab {
          padding: 0 100px;
          margin-top: -25px;

          .landscape-video-top {
            width: 100%;
            max-width: calc(16 / 9 * (100vh - 277px - 230px)) !important;
          }

          .landscape-video-bottom {
            .landscape-video-info {
              max-height: 60px;
            }
          }
        }

        &.landscape-focus {
          justify-content: center !important;
          padding: 0 0px;

          .landscape-video-top {
            width: 100%;
            max-width: 100% !important;
          }
        }

        .landscape-video-bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 0;
          width: 100%;
          overflow: hidden;

          .title {
            color: #3a3a3c;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            padding: 0px 0px 8px 0px;
            width: 100%;
            overflow-wrap: break-word;
          }

          .description-tags-container {
            display: flex;
            flex-direction: column;
            padding: 0px;
          }

          .author-info-container {
            padding: 8px 0px;
          }
        }
      }
    }

    .portrait-sidebar-container {
      height: 100%;
      width: 100%;
      max-height: 1125px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 40px;

      .portrait-video-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 12px;
        height: 100%;
        padding: 0px 40px;
        flex-grow: 1;
        overflow: hidden;

        &.portrait-my-tab {
          padding: 0 90px;
          margin-top: -10px;
        }

        &.landscape-my-tab {
          padding-left: 100px;
          padding-right: 100px;
        }

        .portrait-video-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          height: 100%;
          align-self: left;
          flex-shrink: 0;

          &.portrait-focus {
            padding: 0;
            align-items: center;
          }
        }

        .portrait-video-right {
          height: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin: 0;
          overflow: hidden;

          .title {
            color: #3a3a3c;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            padding: 0px 0px 12px 0px;
            overflow-wrap: break-word;
          }

          .description-tags-container {
            display: flex;
            flex-direction: column;
            padding: 0px;
          }

          .author-info-container {
            padding: 12px 0px;
          }
        }
      }
    }

    &.left-focus {
      display: flex;
      margin-top: auto;
      padding: 24px;
    }

    .playlist-top-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .playlist-bottom-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  .right-part {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100vh;
    z-index: 10;
    padding: 0;
    background-color: #fbfbfb;
    top: 0;
    bottom: 0;
    width: 504px;
    left: calc(100vw - 504px);

    &.opening {
      animation: slideInFromRight 0.3s ease-out;
    }

    &.closing {
      animation: slideOutToRight 0.3s ease-in forwards;
    }

    .right-part-top {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;

      .title {
        color: #3a3a3c;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        padding: 48px 20px 24px 20px;
        overflow-wrap: break-word;
      }

      .description-tags-container {
        display: flex;
        flex-direction: column;
        padding: 0px 20px 24px 20px;
      }

      .author-info-container {
        padding: 8px 20px;
      }

      .right-sidebar {
        padding: 24px 36px;
      }

      .border-line {
        width: 85%;
        align-self: center;
        border-bottom: 1px solid #e5e5ea;
      }

      .bottom-part {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 24px;
        padding-top: 0px;

        .author-information {
          padding-left: 10px;
        }

        .right-sidebar {
          margin-top: auto;
        }
      }
    }

    .right-part-bottom {
      flex-grow: 1;
      overflow-y: auto;

      .description-bottom {
        padding: 24px 20px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .video-description-text {
          font-size: 14px;
          color: #636366;
          font-weight: 500;
          line-height: 20px;
        }

        .description-tags {
          margin-top: -12px !important;
        }
      }
    }

    &.desktop {
      width: 505px;
      left: calc(100vw - 505px);
    }
  }

  &.video-card-my-tab {
    padding: 0 80px;
  }

  &.video-card-focused {
    margin-right: 504px;
    justify-content: center;

    &.desktop {
      margin-right: 505px;
    }
  }

  &.video-card-preview {
    height: 100%;
    margin-bottom: 30px;
  }
}

// AuthCard styling
.infocard-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  .infocard {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(58, 58, 60, 0.1);
    border-radius: 24px;
    border: 1px solid #c7c7cc;
    padding: 20px;
    width: 600px;
    height: 600px;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    &.auth-card {
      border: none;
      box-shadow: none;
      background-color: transparent;
      height: 0px;
    }

    .auth-request-buttons {
      display: flex;
      justify-content: center;
      gap: 30px;
      width: 100%;
    }
  }
}
