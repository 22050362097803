.creator-page {
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    .creator-page-top {
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .description {
        align-items: center;
        text-align: center;
      }
    }
  }

  .creator-page-top {
    display: flex;
    padding: 24px;
    gap: 40px;

    .avatar {
      min-height: 160px;
      max-height: 160px;
      min-width: 160px;
      max-width: 160px;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      p {
        margin-bottom: 0px;
      }
      .name {
        color: #3a3a3c;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      .username {
        color: #636366;
        font-size: 14px;
        font-weight: 500;
      }

      .statistics {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #636366;
        font-weight: 500;
        justify-content: row;
        gap: 12px;

        .vertical-line {
          border-right: 1px #e5e5ea solid;
        }
      }

      .description {
        color: #636366;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        gap: 4px;
        max-height: calc(10vh + 10px);
        overflow-y: auto;
      }
    }
  }
  .no-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 500px);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #636366;
  }
  .video-playlist-filtering {
    padding: 0px 24px 0px 24px;
    border-bottom: 1px #c7c7cc solid;
  }
}
