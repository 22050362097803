.toast-container {
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: auto;
  z-index: 1000;
  pointer-events: none;
  border-radius: 8px;
  padding: 10px 12px;
  background-color: #3a3a3c;
  border: 1px #636366 solid;
  backdrop-filter: blur(24px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 40vw;

  .toast-img {
    width: 24px;
    height: 24px;
  }

  visibility: visible;
  -webkit-animation:
    slide-fadein 0.5s,
    slide-fadeout 0.5s 2.5s;
  animation:
    slide-fadein 0.5s,
    slide-fadeout 0.5s 2.5s;
}
