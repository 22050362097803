.admin-video-row {
  &:hover {
    background-color: #f8fafc;
  }
  &.selected {
    background-color: #f0f7ff;
    border-left: 3px solid #34a7fd;
    border-right: 3px solid #34a7fd;
  }
}

.video-info-column {
  max-width: 300px;
  width: 300px;

  .video-info-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .thumbnail-link {
      flex-shrink: 0;
      width: 80px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f4f6;
      border-radius: 4px;
      overflow: hidden;

      .admin-video-thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        background-color: #f3f4f6;

        // Style for alt text when image fails to load
        &:not([src]),
        &[src=""],
        &[src="#"],
        &[src="null"] {
          font-size: 10px;
          padding: 4px;
          text-align: center;
          color: #6b7280;
          white-space: normal;
          word-break: break-word;
          line-height: 1.2;
        }
      }
    }

    .text-info {
      min-width: 0;
      flex: 1;

      .video-title {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .title-link {
          color: #3b3939;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #34a7fd;
          }
        }
      }
    }
  }
}

.channel-info-column {
  .channel-info-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .admin-video-avatar {
      width: 24px !important;
      height: 24px !important;
      overflow: hidden;
      border-radius: 50%;

      .avatar-text {
        font-size: 12px !important;
        line-height: 24px;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .channel-name {
      font-size: 14px;
      color: #3b3939;
    }
  }
}

/* New Category Column Styles */
.category-column {
  .category-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;

    /* Default style for uncategorized */
    &.uncategorized {
      background: #f3f4f6;
      color: #6b7280;
    }

    /* Common category colors */
    &.programming {
      background: #dbeafe;
      color: #1e40af;
    }

    &.design {
      background: #fce7f3;
      color: #9d174d;
    }

    &.business {
      background: #d1fae5;
      color: #065f46;
    }

    &.marketing {
      background: #fef3c7;
      color: #92400e;
    }

    &.technology {
      background: #e0e7ff;
      color: #3730a3;
    }

    &.science {
      background: #dbeafe;
      color: #1e40af;
    }

    &.health {
      background: #ffedd5;
      color: #9a3412;
    }

    &.education {
      background: #dbeafe;
      color: #1e40af;
    }

    &.lifestyle {
      background: #fce7f3;
      color: #9d174d;
    }

    &.entertainment {
      background: #fef3c7;
      color: #92400e;
    }

    /* Fallback for other categories */
    &:not(.uncategorized):not(.programming):not(.design):not(.business):not(
        .marketing
      ):not(.technology):not(.science):not(.health):not(.education):not(
        .lifestyle
      ):not(.entertainment) {
      background: #e5e7eb;
      color: #4b5563;
    }
  }
}

.date-cell {
  .admin-video-date {
    font-size: 13px;
    color: #6b7280;
  }
  td.numeric-cell {
    text-align: center;
    font-size: 10px;
  }
}

.visibility-column {
  .status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;

    &.public {
      background: #ecfdf3;
      color: #065f46;
    }
    &.private {
      background: #fef2f2;
      color: #991b1b;
    }
    &.unlisted {
      background: #fef3c7;
      color: #92400e;
    }
  }
}

.control-button {
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;
  color: #636366;
  transition: all 0.2s;
  align-items: center;

  &:hover {
    color: #ef4444;
  }
}
