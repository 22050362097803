.tabs-list {
  margin-top: 16px;
  font-size: 16px;
  display: flex;
  gap: 28px;

  .tab-button {
    color: #48484A;
    background-color: #FFFFFF;
    border: none;
    padding: 0;
    padding-bottom: 12px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;

    &.active {
      color: #35A7FF;
      font-weight: 700;
      border-bottom: 2px #35A7FF solid;
    }
  }
}