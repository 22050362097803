.circle-component {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;

  .circle-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 35px;
    height: 35px;

    .central-circle {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
  }
}
