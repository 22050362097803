.spinner-container {
  width: 100%;
  height: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .spinner {
    width: 64px;
    height: 64px;
    position: relative;
    border-radius: 50%;
    background: conic-gradient(from 180deg at 50% 50%, #35a7ff, white 315deg);
    animation: spin 2s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;

    &.small {
      width: 32px;
      height: 32px;

      .empty-space {
        width: 22.4px;
        height: 22.4px;
      }

      .dot {
        left: 13.76px;
        top: 27.2px;
        width: 4.8px;
        height: 4.8px;
      }
    }

    .empty-space {
      width: 44.8px;
      height: 44.8px;
      position: absolute;
      background: white;
      border-radius: 50%;
    }

    .dot {
      left: 27.52px;
      top: 54.4px;
      width: 9.6px;
      height: 9.6px;
      position: absolute;
      background: #35a7ff;
      border-radius: 50%;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
