.list-videos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding-left: 24px;
  padding-bottom: 15px;

  .video-list-title {
    padding: 0;
    color: #3a3a3c;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .video-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    overflow: auto;
    padding-top: 16px;
    margin: 0;
    align-items: flex-start;
    gap: 16px;
    scroll-snap-type: x mandatory;
    padding-right: 52px;

    .single-video {
      max-width: calc(0.8 * 336px);
    }

    .scroll-nav-button {
      display: flex;
      padding: 6px;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 44px;
      height: 44px;
      border: none;
      border-radius: 50%;
      background-color: #c7c7cc;
    }

    .prev-button {
      left: 8px;
    }

    .next-button {
      right: 0px;
    }
  }
}
