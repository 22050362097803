.avatar-logo {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #35a7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-text {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: font-size 0.3s ease;
  font-size: 64px;
}
