@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.hamburger-menu-container {
  width: 100vw;
  height: 100vh;
  z-index: 8;
  position: absolute;
  top: 0;
  background-color: rgba(58, 58, 60, 0.4);
  display: flex;
  justify-content: left;
  align-items: center;

  .hamburger-menu {
    background-color: white;
    height: 100%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    overflow-y: scroll;
    border-right: 1px #e7e7e9 solid;
    animation: slideInFromLeft 0.3s ease-out;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e7e7e9;
      display: none;
    }

    &.scrolling::-webkit-scrollbar-thumb {
      display: block;
    }

    &.closing {
      animation: slideOutToLeft 0.3s ease-in forwards;
    }

    .hamburger-header {
      display: flex;
      flex-direction: row;
      gap: 10px;
      //making it alligned with navigation bar
      margin-top: 4.5px;
      margin-left: 4px;
      padding: 16px 20px 16px 20px;

      .hamburger-header-bars {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      .hamburger-header-logo {
        cursor: pointer;

        .hamburger-header-logo-icon {
          width: 32px;
          height: 32px;
        }

        .hamburger-header-logo-text {
          height: 32px;
        }
      }
    }

    .hamburger-chapter {
      display: flex;
      flex-direction: column;

      .hamburger-chapter-title {
        padding-left: 20px;
        padding-right: 16px;
        color: #c7c7cc;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }

      .hamburger-chapter-item:hover {
        background-color: #35a7ff1a;
      }

      &selected {
        border-right: 4px #3a3a3c solid;
      }
    }

    .hamburger-footer {
      padding: 16px 16px 16px 20px;
      color: #aeaeb2;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-top: auto;
    }
  }
}
