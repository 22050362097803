.editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  .notes-wrapper {
    display: flex;
    width: 100%;
    height: max-content;
    flex-direction: column;
    font-size: 14px;
    row-gap: 8px;
    position: relative;

    .char-count {
      bottom: 12px;
      color: #636366;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      position: absolute;
      right: 16px;
      z-index: 2;
    }
    .normal {
      color: #636366;
    }
    .long {
      color: #ff3559;
    }

    .editor-title {
      color: #3a3a3c;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .rich-text-editor {
      border: 1px solid #c7c7cc;
      border-radius: 10px;
      font-weight: 400;

      p {
        margin: 0;
      }
      a {
        text-decoration: underline;
      }
      .tiptap p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }
      .tiptap p {
        margin-top: 0px;
        margin-bottom: 4px;
      }
      .ProseMirror {
        min-height: 116px;
        padding: 12px 16px;
        max-height: 150px;
        overflow-y: scroll;
        overflow-x: hidden;
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }
      .ProseMirror-focused {
        outline: none;
        max-height: 150px;
        overflow-y: scroll;
        overflow-x: hidden;
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }
      .toolbar {
        border-bottom: 1px solid lightgrey;
        padding: 12px 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
      button {
        border: 1px solid black;
        border-radius: 4px;
        &.is-active {
          background: black;
          color: white;
        }
        &[disabled] {
          border: 1px solid lightgrey;
          //background: gray;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      pre {
        background: #0d0d0d;
        color: #fff;
        font-family: JetBrainsMono, monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        white-space: pre-wrap;
        code {
          //color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
          //color: #616161;
        }
      }
      code {
        background: #0d0d0d;
        padding: 3px;
        color: #c5c8c6;
      }
      blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(13, 13, 13, 0.1);
        margin-left: 16px;
      }
      .tiptap > * + * {
        margin-top: 0.75em;
      }
    }
    .toolbar button {
      border: none;
      background-color: white;
      transition: 0.2s ease-in-out;
    }
    .saving-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;
      color: #3a3a3c;
    }
    .note-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 16px 0px;
      column-gap: 4px;

      .note-options-button {
        max-width: 95px;
        min-width: 95px;
      }
    }
  }
}

.is-increased-height .ProseMirror {
  min-height: min(calc(100vh - 550px), 350px) !important;
}
