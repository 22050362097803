.shortcuts-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.shortcuts-modal {
  position: fixed;
  background-color: white;
  max-width: 90% !important;
  max-height: 90% !important;
  height: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 11;
  width: auto;
  overflow-y: auto;

  .close-modal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 32px;

    padding: 24px 24px 24px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5ea;
    color: #3a3a3c;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    .video-add-cross-sign {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: white;
      width: 32px;
      height: 32px;
    }
  }

  .shortcuts-modal-content {
    display: flex;
    flex-direction: row;
    padding: 16px 36px;
    gap: 24px;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 500px;

      .header {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        color: #3a3a3c;
      }

      .shortcut {
        display: flex;
        flex-direction: row;

        .shortcut-name {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #48484a;
          background-color: #ffffff;
          border: none;
          padding: 0;
          font-weight: 500;
          line-height: 24px;
          word-wrap: break-word;
        }

        .shortcut-button-container {
          margin-left: auto;
          margin-right: 40px;
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;

          .shortcut-button {
            width: 42px;
            height: 42px;
            border: 1px solid #e5e5ea;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
          }

          .wider {
            width: 100px;
          }

          .shortcut-button:hover {
            background-color: #f5f5f7;
          }
        }
      }
    }
  }
}
