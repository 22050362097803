body.modal-open {
  overflow: hidden;
}

.auth-container {
  width: 100vw;
  height: 100vh;
  z-index: 12;
  position: absolute;
  top: 0;
  background-color: rgba(58, 58, 60, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  -webkit-animation: fadein 0.3s ease-in-out;
  animation: fadein 0.3s ease-in-out;

  .auth-form {
    display: flex;
    width: 580px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 20px;
    background-color: white;
    position: relative;
    margin-top: 65px;
    top: 0;

    .plan-box {
      border: 1px solid;
      border-radius: 30px;
      padding: 20px;
      gap: 8px;
      display: flex;
      flex-direction: column;
      min-width: 350px;
      .plan-title {
        font-size: 20px;
        font-weight: 600;
      }
      .plan-details {
        display: flex;
        flex-direction: column;

        p {
          margin: 0px;
          font-size: 14px;
        }
      }
      .btn-wrapper {
        margin-top: auto;
        align-self: center;
      }
    }
    .basic-plan {
      border-color: #3a3a3c;
      // background-color: #3a3a3c1a;
      .current-plan {
        background-color: #35a7ffaa;
      }
    }
    .premium-plan {
      border-color: #35a7ff;
      background-color: #35a7ff1a;
      .current-plan {
        background-color: #35a7ffaa;
      }
      .premium-title {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        .recommended {
          font-size: 12px;
          background-color: #35a7ff;
          border-radius: 10px;
          color: white;
          display: flex;
          align-self: center;
          padding: 6px;
          font-weight: 600;
        }
      }
    }

    .return-button {
      display: flex;
      background-color: transparent;
      border: none;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .auth-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .left-side-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }
      .auth-title {
        color: #3a3a3c;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      .close-button {
        background-color: transparent;
        border: none;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .auth-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      min-height: 22vh;

      .row-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        gap: 12px;
        margin: 0;
      }

      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 6px;
        height: max-content;
        margin: 0;
        label {
          color: #3a3a3c;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }
        label:after {
          content: "*";
          margin: 0 2px;
          color: #ff3559;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .form-input-group {
          display: flex;
          padding: 12px 16px;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-radius: 12px;
          border: 1px solid #c7c7cc;
          color: #8e8e93;
          .form-input {
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            border: none;
            outline: none;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }

          .toggle-pass {
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
        }
      }

      .auth-options {
        display: flex;
        justify-content: flex-end;
        .forgot-password {
          color: #35a7ff;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .policy-agreement-container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .policy-agreement {
          color: #48484a;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: center;

          .upload-terms {
            color: #35a7ff;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          .policy-label-container {
            display: flex;
            align-items: center;
            margin-left: 8px;

            .policy-label {
              margin: 0;

              &.star:after {
                content: "*";
                margin: 0 2px;
                color: #ff3559;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }
            }
          }
        }
      }
      .reset-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .reset-text {
        cursor: pointer;
        color: #35a7ff;
        font-size: 14px;
        &:hover {
          color: #0d8eec;
          text-decoration: underline;
        }
      }
      .reset-completed-text {
        font-size: 14px;
      }
      .submit-button {
        display: flex;
        margin-top: auto;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #35a7ff;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border: none;

        &:disabled {
          background: #aeaeb2;
        }
      }
    }
    .or-divider {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: 100%;
      color: #8e8e93;

      .divider-line {
        width: 100%;
        height: 1px;
        background-color: #c7c7cc;
      }
      .divider-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        padding: 0;
        text-align: center;
      }
    }
    .other-options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .other-option {
        width: 100%;
        padding: 12px 24px;
        border-radius: 12px;
        border: 1px solid #3a3a3c;
        background-color: white;
        cursor: pointer;
        img {
          margin: auto auto;
          float: left;
        }
        p {
          margin: 0 !important;
          color: #48484a;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          float: center;
        }
      }
    }
    .auth-footer {
      color: #48484a;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      width: 100%;
      a {
        color: #35a7ff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
