.time-filter {
  display: flex;
  gap: 12px;
  padding: 8px 0;
  position: relative;

  .time-filter-button {
    padding: 8px 14px;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    background: white;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      background: #34a7fd;
      color: white;
      border-color: #34a7fd;
    }

    &:hover:not(.active) {
      background: #f5f5f5;
    }
  }

  .custom-date-picker {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 8px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    padding: 16px;
    z-index: 10;

    .date-inputs {
      display: flex;
      gap: 8px;
      margin-bottom: 12px;

      input {
        padding: 6px 12px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        &:focus {
          outline: none;
          border-color: #34a7fd;
        }
      }
    }

    .date-actions {
      display: flex;
      gap: 8px;
      justify-content: flex-end;

      button {
        padding: 6px 12px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        background: white;
        cursor: pointer;

        &[type="submit"] {
          background: #34a7fd;
          color: white;
          border-color: #34a7fd;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
