.admin-playlists-wrapper {
  height: 100%;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: white;

  .table-controls {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    width: 100%;
    box-sizing: border-box;

    .search-field {
      position: relative;
      flex: 0 0 300px;

      input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s;
        background: url("../../../assets/search.svg") no-repeat 12px center;
        background-size: 16px;
        padding-left: 36px;

        &:focus {
          outline: none;
          border-color: #34a7fd;
          box-shadow: 0 0 0 2px rgba(52, 167, 253, 0.1);
        }
      }

      .search-indicator {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .delete-selected {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      background-color: transparent;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;

      img {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: #fee2e2;
      }
    }

    .controls-group {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .table-header {
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1300px;

    .delete-selected {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      background-color: transparent;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: #fee2e2;
      }
    }

    .controls-group {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .table-scroll-container {
    overflow-x: auto;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    position: relative;

    .search-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      padding: 8px;
      background: rgba(255, 255, 255, 0.8);
      z-index: 5;
    }

    .playlists-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #e5e7eb;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background: #f8f9fa;

        tr {
          th {
            background: #f8f9fa;
            font-weight: 600;
            user-select: none;
            position: sticky;
            top: 0;
            z-index: 10;
            padding: 1rem 1.5rem;
            text-align: left;
            border-bottom: 1px solid #e5e7eb;
            white-space: nowrap;
            transition: background-color 0.2s;

            &:hover {
              background-color: #f3f4f6;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: #f9fafb;
          }

          &.selected {
            background-color: #f0f7ff;
          }

          td {
            vertical-align: middle;
            padding: 12px 16px;
            font-size: 14px;
            color: #374151;
          }
        }
      }
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .error-message {
    text-align: center;
    color: #ef4444;
    padding: 20px;
    font-size: 16px;
  }
}

.search-bar {
  position: relative;
  flex: 1;
  margin-right: 16px;

  .search-input {
    padding: 8px 12px 8px 36px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    width: 240px;
    font-size: 14px;
    background: url("../../../assets/search.svg") no-repeat 12px center;
    background-size: 16px;

    &:focus {
      outline: none;
      border-color: #34a7fd;
      box-shadow: 0 0 0 2px rgba(52, 167, 253, 0.1);
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
}

.checkbox-header {
  width: 40px;
}

.checkbox-column {
  width: 40px;
  text-align: center;
}

.actions-column {
  width: 80px;
  text-align: center;
}

.sortable {
  cursor: pointer;
  position: relative;
  padding-right: 24px;

  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    opacity: 0.3;
  }

  &.asc::after {
    border-bottom: 4px solid #6b7280;
    opacity: 1;
  }

  &.desc::after {
    border-top: 4px solid #6b7280;
    opacity: 1;
  }

  &:hover::after {
    opacity: 0.7;
  }
}

.numeric-header {
  text-align: center;
}

.date-header {
  white-space: nowrap;
}

.table-scroll-container {
  .playlists-table {
    th {
      &.numeric-header {
        text-align: center;
      }
    }
    td {
      &.numeric-cell {
        text-align: center;
      }
    }
  }
}
