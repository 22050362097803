.upload-popup-form-background-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(58, 58, 60, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-y: auto;
  z-index: 3;
  pointer-events: none;

  .upload-popup-form {
    max-width: min(1200px, 95%);
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #e7e7e9;
    border-radius: 12px;
    min-height: calc(100vh - 224px);
    display: flex;
    flex-direction: column;
    pointer-events: auto;

    .upload-popup-form-header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5ea;
      color: #3a3a3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;

      .upload-popup-form-cross-sign {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: white;
        width: 24px;
        height: 24px;
      }
    }

    @media (orientation: portrait) {
      .upload-popup-form-body {
        flex-direction: column;
        .upload-popup-form-body-right {
          border-left: 0px;
          border-top: 1px solid #e7e7e9;
        }
      }
    }

    @media (orientation: landscape) {
      .upload-popup-form-body {
        .upload-popup-form-body-right {
          border-left: 1px solid #e7e7e9;
        }
      }
    }

    .upload-popup-form-body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-height: 80%;
      flex-grow: 1;
      align-items: stretch;

      //for uploading a-b steps
      .upload-popup-form-body-left {
        padding: 24px 24px;
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .upload-popup-form-body-learning-objective {
          position: relative;
        }

        // for editing details c-d steps
        .upload-popup-form-body-header {
          width: 100%;
          color: #2d3648;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
        }

        @media (max-width: 750px) {
          .upload-popup-form-preview {
            flex-direction: column;
          }
        }

        .upload-popup-form-preview {
          display: flex;
          gap: 20px;

          .upload-popup-form-preview-right {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;

            .upload-popup-form-preview-right-title {
              color: #aeaeb2;
              font-size: 24px;
              font-weight: 700;
              line-height: 32px;
              word-break: break-word;

              &.has-title {
                color: #3a3a3c;
              }
            }

            .upload-popup-form-preview-right-description {
              color: #aeaeb2;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              overflow: hidden;
              display: block;
              white-space: pre-wrap;
              text-overflow: ellipsis;

              &.has-description {
                color: #48484a;
              }
            }

            .upload-popup-form-preview-right-tag {
              color: #aeaeb2;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;

              &.has-tag {
                color: #35a7ff;
              }
            }

            .upload-popup-form-preview-right-divider {
              border: 1px #e5e5ea solid;
            }

            .upload-popup-form-preview-right-visibility {
              display: flex;
              align-items: center;

              .upload-popup-form-preview-right-visibility-word {
                margin-right: 12px;
                color: #636366;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
              }
            }
          }
        }

        .upload-popup-form-body-comments {
          display: flex;
          justify-content: space-between;
          color: #3a3a3c;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        // for uploading a-b steps
        .upload-popup-form-body-insert {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        .upload-popup-form-body-terms {
          padding: 24px 24px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .upload-popup-form-body-term {
            color: #636366;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;

            .upload-terms {
              color: #35a7ff;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }

      .upload-popup-form-body-right {
        #tips {
          color: #2d3648;
        }

        #example {
          color: #2d3648;
          margin-left: 40px;
          margin-bottom: 0px;
        }

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px 24px;
        flex: 1;
        .pre-publish-block {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .pre-publish-block-info {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .pre-publish-block-headline {
              color: #8e8e93;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }

            .pre-publish-block-description {
              color: #48484a;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              overflow-wrap: break-word;
              word-break: break-word;
            }
          }
        }
        .upload-popup-form-body-right-title {
          color: #3a3a3c;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 24px;
        }

        .upload-popup-form-body-right-requirements {
          display: flex;
          flex-direction: flex-start;
          align-items: flex-start;
          gap: 16px;
          margin-bottom: 20px;
          color: #636366;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          .upload-popup-form-body-right-icon {
            height: 24px;
            width: 24px;
          }
        }

        .upload-popup-form-hori-line {
          border-top: 1px solid #e5e5ea;
          margin-top: 24px;
          margin-bottom: 24px;
          width: 100%;
        }

        .upload-popup-form-body-right-video-guidelines {
          color: #48484a;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;

          .upload-terms {
            color: #35a7ff;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }
    }

    //for c-d steps
    .upload-bottom-component {
      display: flex;
      align-items: right;
      justify-content: flex-end;
      padding: 12px 24px;
      border-top: 1px #e5e5ea solid;

      .upload-bottom-component-right {
        display: flex;
      }
    }
  }
}

.upload-file {
  padding: 40px min(120px, calc(50% - 100px));
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px dashed #aeaeb2;
  border-radius: 20px;
  gap: 20px;

  .upload-file-icon {
    vertical-align: bottom;
    justify-content: bottom;
    height: 80px;
    width: 80px;
  }

  .upload-file-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    .upload-file-text-main-title {
      color: #3a3a3c;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: center;
    }

    .upload-file-text-title {
      color: #636366;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }

  .upload-instructions {
    color: #636366;
    font-size: 12px;
    font-weight: 500;
  }
}
