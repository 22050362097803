.admin-card-row {
  &:hover {
    background-color: #f8fafc;
  }
  &.selected {
    background-color: #f0f7ff;
    border-left: 3px solid #34a7fd;
    border-right: 3px solid #34a7fd;
  }
}

.card-info-column {
  max-width: 300px;
  width: 300px;

  .card-info-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .card-preview {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .admin-card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .card-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: white;
      }
    }

    .text-info {
      min-width: 0;
      flex: 1;

      .card-title {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-type {
        font-size: 12px;
        color: #6b7280;
        margin-top: 4px;
      }
    }
  }
}

.channel-column {
  width: 180px;

  .author-info {
    display: flex;
    align-items: center;
    gap: 8px;

    .author-avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .author-name {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.date-cell {
  width: 120px;

  .admin-card-date {
    font-size: 13px;
    color: #6b7280;
  }
}

.type-cell {
  width: 120px;
  font-size: 13px;

  .type-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;

    &.flashcard {
      background: #e0f2fe;
      color: #0369a1;
    }
    &.multiple_choice,
    &.assessment {
      background: #f0fdf4;
      color: #166534;
    }
    &.informational,
    &.knowledge {
      background: #f1f5f9;
      color: #475569;
    }
    &.unknown {
      background: #f3f4f6;
      color: #6b7280;
    }
  }
}

.numeric-cell {
  width: 80px;
  text-align: center;
  font-size: 13px;
}

.visibility-column {
  width: 100px;

  .status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;

    &.public {
      background: #ecfdf3;
      color: #065f46;
    }
    &.private {
      background: #fef2f2;
      color: #991b1b;
    }
    &.unlisted {
      background: #fef3c7;
      color: #92400e;
    }
  }
}

.actions-column {
  width: 80px;
  text-align: center;
}

.control-button {
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;
  color: #636366;
  transition: all 0.2s;
  align-items: center;

  &:hover {
    color: #ef4444;
  }
}
