@import "../../styles/variables.scss";

.navbar-main {
  max-height: 74px;
  height: 74px;
  width: 100%;
  background-color: $card-background-color;
  border-bottom: 1px solid $navbar-border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  z-index: 2;

  .hamburger-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0;

    .hamburger-header-bars {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    .hamburger-header-logo {
      cursor: pointer;

      .hamburger-header-logo-icon {
        width: 32px;
        height: 32px;
      }

      .hamburger-header-logo-text {
        height: 32px;
      }
    }
  }
  .search-bar-component {
    display: flex;
    width: 40%;
    position: relative;

    .search-bar {
      display: flex;
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: 50px;
      border: 1px solid #c7c7cc;
      background: #fff;
      backdrop-filter: blur(8px);
      color: #8e8e93;
      font-size: 16px;

      input {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        max-height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .ai-search {
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin-left: 4px;
      align-items: center;
    }

    .search-bar-component-magnifier {
      margin-left: auto;
      margin-right: 16px;
      cursor: pointer;
    }
  }

  &.full-width {
    width: 100%;
  }

  .navbar-right {
    min-width: 90px;
    .navbar-group {
      display: flex;
      align-items: center;
      gap: 24px;

      .navbar-auth {
        display: flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        border: none;
      }

      .sign-up {
        background-color: white;
        border: 1px solid #636366;
        color: #3a3a3c;
      }

      .sign-in {
        color: white;
        background-color: #35a7ff;
      }

      .navbar-group-item {
        color: #3a3a3c;
        font-size: 20px;
        cursor: pointer;
        position: relative;

        .avatar {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
