.public-profile-tab {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 300px;

  .public-profile-headline {
    color: #3a3a3c;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }

  @media (max-width: 850px) {
    .avatar-block {
      flex-direction: column;
      .right-side {
        align-items: center;
        text-align: center;
      }
    }
  }

  .avatar-block {
    display: flex;
    gap: 40px;
    align-items: center;

    .avatar {
      height: 160px;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      .channel-name {
        color: #3a3a3c;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 12px;
      }

      .username {
        color: #636366;
        font-size: 14px;
        font-weight: 500;
      }

      .links {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .upload {
          color: #35a7ff;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 6px;

          &:hover {
            text-decoration: underline #35a7ff;
            cursor: pointer;
          }
        }
      }

      .instruction {
        color: #8e8e93;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
