.text-area-component {
  display: flex;
  flex-direction: column;
  color: #3a3a3c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  gap: 6px;

  .text-area-label {
    .text-area-label-img {
      margin-left: 4px;
    }
  }

  .text-area-component-bottom {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    margin-bottom: -6px;

    .text-area-component-input {
      width: 100%;
      padding: 12px 16px;
      border-radius: 12px;
      border: 1px #c7c7cc solid;
      color: #3a3a3c;
      min-height: 80px;
      resize: none;
      height: 100%;

      &::placeholder {
        color: #8e8e93;
      }

      &:hover {
        border: 1px #3a3a3c solid;
      }

      &:focus {
        border: 1px #0d8eec solid;
        outline: none;
        caret-color: #0d8eec;
        caret-shape: block;
      }
    }

    .text-area-component-input::hover {
      border: 1px #3a3a3c solid;
    }

    .char-count {
      position: absolute;
      bottom: 12px;
      right: 16px;
      color: #636366;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
