.settings-tab {
  display: flex;
  flex-direction: column;
  gap: 34px;

  .password-headline {
    color: #3a3a3c;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }

  .reset-text {
    cursor: pointer;
    color: #35a7ff;
    &:hover {
      color: #0d8eec;
      text-decoration: underline;
    }
  }

  .name {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .first {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .settings-row {
    display: flex;
    flex-direction: row;
    gap: 10%;
    align-items: center;

    .left-part {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .right-part {
      margin-left: auto;
      min-width: 170px;
    }
  }

  .settings-line {
    width: 100%;
    height: 1px;
    background-color: #e7e7e9;
    margin-bottom: 20px;
  }
}
