.share-module {
  display: flex;
  margin: 12px;
  border: 1px solid #e5e5ea;
  background-color: white;
  padding: 24px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 380px;
  box-shadow: 0px 12px 24px 0px rgba(1, 12, 27, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  z-index: 1;

  &.right-align {
    right: 0;
    left: auto;
  }

  .module-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    color: rgba(72, 72, 74, 1);
    padding: 0px 8px;

    button {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      font-size: 22px;

      &:active {
        transform: scale(0.95);
      }
    }
  }
  .share-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    .share-option {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      flex: 1;

      p {
        color: #48484a;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
      }

      .other-option {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #f4f4f4;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .link-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    cursor: pointer;

    .link-label-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .link-label {
        color: #636366;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        padding: 0;
        padding-left: 8px;
        cursor: pointer;

        &.active {
          color: #0d8eec;
          font-weight: 700;
        }
      }
    }

    .link-input {
      display: flex;
      padding: 10px 16px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 12px;
      border: 1px solid #c7c7cc;

      .copy-link {
        color: #3a3a3c;
        height: 20px;
        max-width: 100%;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      button {
        color: #0d8eec;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
