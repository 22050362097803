.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  font-weight: 700;
  color: #3a3a3c;

  .dashboard-header {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px #c7c7cc solid;

    .dashboard-title {
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  .tab-content {
    //margin-top: 30px;
    //padding: 30px 50px 40px 0;
    //padding: 0 20px;
    //margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 107px);
    width: 100%;
  }
}
