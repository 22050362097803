.report-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: rgba(58, 58, 60, 0.4);

  .report-dialog {
    display: flex;
    width: 320px;
    padding: 20px;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    border-color: 1px solid #e5e5ea;
    background: white;
    max-height: 500px;
    box-shadow: 0px 12px 24px 0px rgba(1, 12, 27, 0.1);

    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #48484a;

      button {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        font-size: 22px;

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .report-options {
      margin: 0;
      padding: 0;
      // max-height: 200px;
      overflow-y: auto;
      color: #3a3a3c;
      li {
        display: flex;
        padding: 12px 8px;
        align-items: center;
        gap: 10px;

        input {
          cursor: pointer;
          width: 20px;
          height: 20px;
          &:active {
            color: #35a7ff;
          }
        }
        label {
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }
      }
    }

    .report-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
