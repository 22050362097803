.toggle-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .toggle-component-bar {
    width: 90%;
    margin-left: 10%;
    height: 55%;
    background-color: #c7c7cc;
    position: absolute;
    transition: background-color 0.3s;
    border-radius: 100px;

    &.on {
      background-color: #90cbf8;
    }
  }

  .toggle-component-circle {
    height: 80%;
    border-radius: 50%;
    position: absolute;
    transition: transform 0.3s;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: #35a7ff;
    color: #fff;

    &.on {
      transform: translateX(100%);
    }
  }
}
