// AuthorInfo.scss
@import "../../styles/variables";

.author-info {
  .subscriber-count {
    color: $subscriber-count-color;
    background-color: $subscriber-count-background-color;
    border-radius: $subscriber-count-border-radius;
    padding: $subscriber-count-padding;
    font-size: $subscriber-count-font-size;
  }
}

.avatar-component {
  display: flex;
  flex-direction: column;

  .person-info {
    display: flex;
    align-items: center;

    .avatar {
      width: 48px;
      height: 48px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .subscriber-info {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .author-name {
        color: #3a3a3c;
        font-size: 18px;
        font-family: Inter;
        font-weight: 600;
        line-height: 26px;
        word-wrap: break-word;
      }

      .subscriber-count {
        font-size: 0.9rem;
        color: #606060;
        margin-bottom: 4px;
      }
    }
  }

  .subscribe-button {
    padding: 8px 16px;
    margin-left: 16px;
    background-color: $subscriber-count-background-color;
    color: #fff;
    border: none;
    border-radius: $button-border-radius;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $button-dark-color;
    }
  }
}

.author-metadata p {
  margin-bottom: 0;
  font-weight: 400;
  color: #636366;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  padding-right: 0.5rem;
}

.author-metadata :not(:first-child) {
  padding-left: 0.5rem;
}

.author-metadata :not(:last-child) {
  border-right: 1px solid #e5e5ea !important;
}
