.card-container {
  height: 100%;
  aspect-ratio: 9 / 16;
  --scale-coef: 1;
  --color-schema: #0d8eec;
  perspective: 5000px;
  transform-style: preserve-3d;
  perspective-origin: center;
  padding: 1px;

  .flipper {
    position: relative;
    width: 100%;
    min-width: 100%;
    height: 100%;
    transition: transform 0.7s;
    transform-style: preserve-3d;
    transform-origin: center center;

    &.flipped {
      transform: rotateY(180deg);
    }
  }

  .card-front,
  .card-back {
    display: flex;
    flex-direction: column;
    border: 1.5px solid #eaeaec;
    height: 100%;
    aspect-ratio: 9 / 16;
    position: absolute;
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    box-sizing: border-box;
  }

  .card-back {
    transform: rotateY(180deg);
  }

  .has-background {
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #ffffff;
  }

  .question {
    font-weight: 400;
    min-height: 7em;

    .card-description {
      p {
        margin: 0;
      }
    }

    a {
      word-break: break-word;
    }

    .explanation-title {
      color: #3a3a3c;
      font-weight: 600;
      margin-bottom: 1em;
    }
  }

  .image-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: calc(6px * var(--scale-coef));
    }
  }

  .code {
    overflow: hidden;
    max-height: 10.8em;
    overflow-y: auto;
  }

  .explanation-answer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .explanation {
      background-color: #0d8eec;
      color: white;
      border-radius: 12px;
      padding: 12px;
      border: 1px solid #eaeaec;

      span {
        font-weight: 600;
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
      gap: calc(12px * var(--scale-coef));

      .answer {
        width: 100%;
        height: calc(40px * var(--scale-coef));
        display: flex;
        justify-content: center;
        background-color: white;
        color: black;
        align-items: center;
        gap: calc(8px * var(--scale-coef));
        border: none;
        font-weight: 500;
        white-space: nowrap;
        border-radius: calc(10px * var(--scale-coef));
        border: calc(1.5px * var(--scale-coef)) solid #eaeaec;
        cursor: pointer;

        &.selected {
          border: calc(1.5px * var(--scale-coef)) var(--color-schema) solid;

          &:hover {
            border: calc(1.5px * var(--scale-coef)) var(--color-schema) solid;
          }
        }

        &.green {
          background-color: green;
          color: white;
          cursor: auto;
          border: calc(1.5px * var(--scale-coef)) green solid;
        }

        &.red {
          background-color: red;
          color: white;
          cursor: auto;
          border: calc(1.5px * var(--scale-coef)) red solid;
        }

        &.grey {
          background-color: #eaeaec;
          cursor: auto;
        }

        &:hover {
          border: calc(1.5px * var(--scale-coef)) #3a3a3c solid;
        }
      }

      .show-answer {
        width: 100%;
        height: calc(40px * var(--scale-coef));
        justify-content: center;
        align-items: center;
        font-weight: 500;
        border-radius: calc(10px * var(--scale-coef));
        background-color: var(--color-schema);
        color: white;
        border: calc(1px * var(--scale-coef)) var(--color-schema) solid;

        &:hover {
          background-color: var(--color-schema);
        }

        &.correct {
          background-color: green;
          border: calc(1px * var(--scale-coef)) green solid;
        }

        &.incorrect {
          background-color: red;
          border: calc(1px * var(--scale-coef)) red solid;
        }
      }
    }

    .button-group {
      display: flex;
      gap: calc(12px * var(--scale-coef));
      width: 100%;

      .explanation-button {
        width: 150%;

        &.correct {
          background-color: green;

          &:hover {
            background-color: green;
          }
        }

        &.incorrect {
          background-color: red;

          &:hover {
            background-color: red;
          }
        }
      }
    }
  }
}
