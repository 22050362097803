.playlist-item-component {
  display: flex;
  width: calc(80vw - 100px);
  height: 100%;
  position: relative;
  padding: 0px 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  .playlist-part {
    max-width: calc(100% - 220px);
    flex-grow: 1;
  }

  .button-part {
    min-width: 200px;
    width: auto;
    display: flex;
    flex-direction: right;
    align-items: right;
    margin-left: auto;

    .button-container {
      margin-left: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
