@import "../../styles/variables.scss";

.sidebar-btn {
  min-height: 45px;
  min-width: 45px;
  padding: 4px;
  border: none;
  background-color: #f4f4f4;
  color: black;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-self: center;
  flex-direction: column;
  transition: 0.2s ease-in;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.sidebar-btn:hover {
  .tooltip-text {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.4s;
  }
}

.sidebar-btn:not(:hover) .tooltip-text {
  transition-delay: 0.2s;
  opacity: 0;
  visibility: hidden;
}
