.inline-code {
  background-color: black;
  color: #c5c8c6;
  font-weight: 400;
  padding: 0 0.2em;
  border-radius: 3px;
}

.code-block {
  background-color: black;
  color: #c5c8c6;
  padding: 1em;
  border-radius: 5px;
  overflow: auto;
  white-space: pre-wrap;
  font-weight: 400;
  margin-top: 10px;
}
.blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
  margin-left: 16px;
  font-size: inherit;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

ol,
ul {
  margin-left: 1rem;
  padding-left: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

p {
  margin-bottom: 0.625rem !important;
}
