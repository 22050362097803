@import "../../styles/variables.scss";

.playlist-dropdown-container {
  position: relative;

  .label-option {
    .sidebar-btn {
      min-height: 45px;
      min-width: 45px;
      padding: 4px;
      border: none;
      background-color: #f4f4f4;
      color: black;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      justify-self: center;
      flex-direction: column;
      transition: 0.2s ease-in;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: scale(0.95);
      }

      &.selected {
        background-color: #35a7ff;
      }
    }

    .sidebar-btn:hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.4s;
      }
    }

    .sidebar-btn:not(:hover) .tooltip-text {
      transition-delay: 0.2s;
      opacity: 0;
      visibility: hidden;
    }

    .btn-selected {
      background-color: #35a7ff !important;
    }

    .dashboard-btn {
      height: 36px;
      cursor: pointer;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
      .tooltip-text {
        transition-delay: 0s !important;
      }
    }
  }

  .dropdown-multiple-component {
    position: absolute;
    right: -50px;
    overflow: scroll;
    z-index: 100;

    .create-playlist {
      padding: 0px 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      column-gap: 8px;
      color: #35a7ff;
      background-color: transparent;
      border: none;

      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}
