.text-input-component {
  display: flex;
  flex-direction: column;
  color: #3a3a3c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  gap: 6px;

  .label {
    display: flex;
    align-items: row;
    gap: 3px;

    .star {
      color: #ff3559;
    }
  }

  .text-input-component-bottom {
    width: 100%;

    .input {
      position: relative;

      .text-input-component-input {
        width: 100%;
        padding: 12px 16px;
        padding-right: 40px;
        border-radius: 12px;
        border: 1px #c7c7cc solid;
        color: #3a3a3c;

        &::placeholder {
          color: #8e8e93;
        }
        &:disabled {
          background-color: #e7e7e9;
          &:hover {
            border: 1px #c7c7cc solid;
          }
        }
        &:hover {
          border: 1px #3a3a3c solid;
        }

        &:focus {
          border: 1px #0d8eec solid;
          outline: none;
          caret-color: #0d8eec;
          caret-shape: block;
        }
      }

      .text-input-component-input::hover {
        border: 1px #3a3a3c solid;
      }
    }

    .icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      &.on {
        background-color: #0d8eec;
        border-radius: 100%;
        width: 16px;
        height: 16px;
      }

      &.off {
        border-radius: 100%;
        border: 1px #c7c7cc solid;
        width: 16px;
        height: 16px;
      }
    }

    .char-count {
      bottom: 12px;
      color: #636366;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      position: absolute;
      right: 16px;
      z-index: 2;
    }
    .normal {
      color: #636366;
    }
    .long {
      color: #ff3559;
    }
  }
}
