.account-info {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .account-info-headline {
    color: #3a3a3c;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }

  @media (max-width: 850px) {
    .name {
      flex-direction: column;
    }
  }
  .name {
    display: flex;
    gap: 16px;

    .first {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
  .optional-container {
    display: flex;
    gap: 5px;
    .optional-text {
      font-style: italic;
      margin: 0px;
      font-size: 12px;
      color: #8e8e93;
    }
  }
}
