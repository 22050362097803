.asscard-container {
  height: 100%;
  overflow: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .card-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;

    .card-row-left {
      width: 200px;
      display: flex;
      flex-direction: column;

      .headline {
        color: #48484a;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .description {
        color: #636366;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .card-row-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.checkboxes {
        flex-direction: row;
      }

      &.card-editor {
        margin: -20px -24px;
      }

      .upload-image {
        padding: 18px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        border: 1px dashed #aeaeb2;
        border-radius: 12px;
        gap: 4px;

        input {
          display: none;
        }

        .upload-image-title {
          color: #48484a;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

          .remove {
            color: #ff3559;
            cursor: pointer;
          }
        }
        .upload-button {
          color: #58a5f8;
          cursor: pointer;
        }

        .upload-image-instruction {
          margin: 0px;
          color: #636366;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .background-image {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .background-image-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .background-image-toggle {
          width: 40px;
          height: 25px;
          position: relative;
        }
      }

      .dropdowns {
        display: flex;
        flex-direction: row;
        gap: 32px;

        .dropdown-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 1;

          .headline {
            color: #48484a;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }

          .dropdown-card {
            width: 200px;
          }

          .dropdown-visibility {
            width: 150px;
          }

          .dropdown-language {
            width: 120px;
          }

          &.vertical {
            flex-direction: column;
          }
        }
        &.vertical {
          flex-direction: column;
        }
      }
    }

    &.mobile {
      flex-direction: column;
    }
  }

  &.vertical {
    overflow: visible;
    border-right: none;
  }

  .generate-button {
    margin-top: 16px;
  }
}
