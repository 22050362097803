.dropdown-menu-playlist-container {
  position: absolute;
  z-index: 1000;
  top: -12px;
  left: -12px;
  margin-top: 8px;
  padding: 12px;

  .dropdown-menu-playlist-component {
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    width: 240px;
    box-shadow: 0px 12px 24px rgba(1, 12, 27, 0.1);
    border-radius: 16px;
    border: 1px #e5e5ea solid;
    background-color: white;
    overflow: auto;

    .dropdown-headline {
      color: #48484a;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      word-wrap: break-word;
      padding-left: 8px;
      margin-bottom: 12px;
    }

    .dropdown-list-container {
      height: auto;
      max-height: 250px;
      overflow: auto;

      .dropdown-list {
        margin: 0;
        padding: 0;
        cursor: pointer;

        .dropdown-list-item {
          display: flex;
          justify-content: space-between;
          padding: 12px 8px;
          align-items: center;
          border-radius: 8px;

          .dropdown-list-item-left {
            display: flex;
            align-items: left;

            .dropdown-list-option-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;

              .dropdown-icon {
                width: 24px;
                height: 24px;
              }

              &.no-photo {
                width: 0px;
                margin: -7px;
              }
            }

            .dropdown-list-option-name {
              margin-left: 16px !important;
              font-size: 16px !important;
              font-weight: 500 !important;
              line-height: 24px !important;
            }
          }

          .dropdown-list-option-checkmark {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #35a7ff;
            width: 24px;
            height: 24px;
            font-size: 24px;
          }
        }
        :hover {
          background-color: #ebf6ff;
        }
      }
    }
  }

  .dropdown-playlist-tree {
    width: 250px;
  }
}

.drop-menu-right-container {
  left: -220px;
}

.dropdown-playlist-tree-container {
  min-height: 400px;
}
