.not-found-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  p {
    font-size: 18px;
    font-weight: 400;
  }
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    img {
      margin: 0px 10px;
    }
  }

  .not-found-text {
    text-align: center;
  }
}
