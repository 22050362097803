.upload-ass-card-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(58, 58, 60, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-y: auto;
  z-index: 3;
  pointer-events: none;

  .upload-ass-card {
    width: 75%;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #e7e7e9;
    border-radius: 12px;
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    pointer-events: auto;

    .upload-header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5ea;
      color: #3a3a3c;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;

      .upload-cross-sign {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: white;
        width: 24px;
        height: 24px;
      }
    }

    .upload-body {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      overflow: auto;

      .upload-body-left {
        flex-grow: 1;
        border-right: 1px #eaeaec solid;
        height: auto;
        min-width: 300px;
      }

      .upload-body-right {
        padding: 32px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        overflow: hidden;
        flex-shrink: 0;

        .title {
          color: #3a3a3c;
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
        }

        .preview-and-colors {
          display: flex;
          flex-direction: row;
          gap: 16px;
          height: 100%;
          width: auto;
          overflow: hidden;
          height: calc(100vh - 430px);

          .card-container-wrapper {
            display: flex;
            width: calc(100% - 60px);
            width: calc(100% - 60px);
            height: 100%;
          }

          .colors {
            width: 40px;
            margin-left: auto;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }

      &.vertical {
        flex-direction: column;
        overflow: auto;
      }
    }

    .upload-footer {
      padding: 12px;
      width: 100%;
      margin-top: auto;
      border-top: 1px #e7e7e9 solid;

      .upload-footer-buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-end;
      }
    }
  }
}
