.tag-input-component {
    display: flex;
    flex-direction: column;
    color: #3A3A3C;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    gap: 6px;

    .tag-input-label {
        .tag-input-label-img {
            margin-left: 4px;
        }
    }

    .tag-input-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: text;
        width: 100%;
        padding: 12px 16px;
        border-radius: 12px;
        border: 1px #C7C7CC solid; 
        color: #3A3A3C;
        height: 100%;

        .tag-item {
            margin-right: 4px;
            padding: 4px 8px;
            background-color: #3A3A3C;
            border-radius: 6px;
            display: flex;
            align-items: center;
            color: white;
            margin-bottom: 2px;
            margin-top: 2px;

            .tag-remove-button {
                margin-left: 4px;
                border: none;
                background: none;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .tag-input-field {
            flex-grow: 1;
            border: none;
            color: #3A3A3C;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            caret-color: #0D8EEC;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #AEAEB2;
            }
        }

        &:hover {
            border: 1px #3A3A3C solid;
        }
        
    }

    .tag-suggestions {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: -6px;
        padding: 0;
        
    }
}