.tooltip-thing {
  position: relative;
  display: inline-block;

  .info-icon {
    position: relative;
    margin-left: 4px;
  }
  .tooltip-text {
    background-color: #3a3a3c;
    color: #f4f4f4;
    border-radius: 5px;
    padding: 1px 5px;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 500;
    visibility: hidden;
    position: absolute;
    z-index: 3;
    a{
      color: #35a7ff;
    }
  }

  .long-text {
    white-space: pre-line;
    width: 200px;
  }

  .right {
    left: 30px;
  }
  .bottom {
    top: 40px;
    left: 50%;
    transform: translateX(-40%);
    align-self: center;
  }
  .sidebar {
    left: 30px;
    bottom: 5px;
  }
  .playlist{
    top: 20px;
    white-space: pre-line;
    max-width: 155px;
    width: max-content;
    align-self: center;
    word-break: break-word;
  }
  .video{
    margin-left:11px;
  }
}
.tooltip-thing:hover {
  .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-thing:not(:hover) .tooltip-text {
  opacity: 0;
  visibility: hidden;
}
