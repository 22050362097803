.my-notes {
  display: flex;
  flex-direction: column;

  .initial-heading {
    padding: 16px 24px;
    display: flex;
    font-size: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .initial-heading-button {
      margin-left: auto;
      text-decoration: none;
    }
  }

  .table-headings-component {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px #e7e7e9 solid;
    border-bottom: 1px #e7e7e9 solid;
    color: #8e8e93;
    font-size: 14px;
    font-weight: 600;
    align-items: center;

    .first-child {
      min-width: 516px;
    }
    .second-child {
      width: 836px;
    }
    .controls-child {
      min-width: 156px;
    }
  }
}

.note-item-component {
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  align-items: flex-start;

  .note-block {
    display: flex;
    flex-direction: space-between;
    width: 836px;

    .note-edit {
      width: 100%;
      margin: -20px;
    }

    .note-block-no-edit {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .note-description {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        max-width: 688px;
        word-break: break-word;
        color: #636366;
        max-height: calc(2 * 1.3em);
        padding-right: 4px;
        p {
          margin-bottom: 4px;
        }
      }

      .note-description.expanded {
        display: block;
        max-height: none;
      }

      .note-button {
        margin-left: -12px;
      }
    }

    .inline-code {
      background-color: black;
      color: #c5c8c6;
      font-weight: 400;
      padding: 0 0.2em;
      border-radius: 3px;
    }

    .code-block {
      background-color: black;
      color: #c5c8c6;
      padding: 1em;
      border-radius: 5px;
      overflow: auto;
      white-space: pre-wrap;
      margin-top: 10px;
    }
  }

  .controls-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    min-width: 156px;

    .control-icon {
      height: 36px;
      cursor: pointer;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .control-icon-img {
      width: 24px;
      height: 24px;
    }
  }
}
