.admin-playlist-card {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid #e5e7eb;
  min-width: 1300px;

  &:hover {
    background-color: #f8fafc;
  }

  &.selected {
    background-color: #f0f7ff;
    border-left: 3px solid #2563eb;
    border-right: 3px solid #2563eb;
  }

  .selection-container {
    width: 40px;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      cursor: pointer;
      accent-color: #35a7ff;
    }
  }

  .content-group {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    .first-child {
      width: 380px;
      display: flex;
      align-items: center;
      gap: 16px;

      .playlist-thumbnail {
        width: 120px !important;
        height: 70px !important;
        flex-shrink: 0;
        border-radius: 6px;
        border: 1px solid #e5e7eb;
        overflow: hidden;
      }

      .playlist-details {
        flex: 1;
        min-width: 0;

        .playlist-title {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: #1f2937 !important;
          margin-bottom: 4px;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .author-info {
          display: flex;
          align-items: center;
          gap: 8px;

          .author-name {
            font-size: 12px;
            color: #6b7280;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .second-child {
      width: 140px;
      color: #6b7280;
      font-size: 14px;
    }

    .third-child {
      width: 100px;

      .playlist-stats {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #636366;
        gap: 4px;
        padding-right: 20px;

        .stat-item {
          display: flex;
          width: 60px;
          justify-content: space-between;
        }
      }
    }

    .fourth-child {
      width: 120px;
      display: flex;
      justify-content: center;

      .status-badge {
        padding: 2px 8px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 500;

        &.public {
          background-color: #dcfce7;
          color: #15803d;
        }

        &.private {
          background-color: #fee2e2;
          color: #dc2626;
        }
      }
    }

    .fifth-child {
      width: 80px;
      display: flex;
      justify-content: center;

      .playlist-controls {
        display: flex;
        justify-content: center;

        .control-button {
          padding: 6px;
          background: none;
          border: none;
          cursor: pointer;
          color: #6b7280;

          &:hover {
            color: #ef4444;
          }
        }
      }
    }
  }
}

.admin-playlist-row {
  &:hover {
    background-color: #f8fafc;
  }
  &.selected {
    background-color: #f0f7ff;
    border-left: 3px solid #34a7fd;
    border-right: 3px solid #34a7fd;
  }

  .checkbox-column {
    padding-left: 24px;
    width: 64px;
    text-align: center;

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      cursor: pointer;
      accent-color: #35a7ff;
      margin: 0;
    }
  }
}

.playlist-info-column {
  max-width: 300px;
  width: 300px;

  .playlist-info-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .thumbnail-link {
      flex-shrink: 0;

      .admin-playlist-thumbnail {
        width: 120px !important;
        height: 70px !important;
        border-radius: 6px;
        border: 1px solid #e5e7eb;

        .admin-playlist-thumbnail-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.2s ease;
          background-color: transparent;
          border: none;
        }

        &:hover .admin-playlist-thumbnail-image {
          transform: scale(1.05);
        }
      }
    }

    .text-info {
      min-width: 0;
      flex: 1;

      .playlist-title {
        font-size: 14px !important;
        font-weight: 500 !important;
        margin: 0;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .title-link {
          color: #1f2937 !important;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #2563eb !important;
          }
        }
      }
    }
  }
}

.author-info-column {
  .author-info-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .admin-playlist-avatar {
      width: 24px !important;
      height: 24px !important;
      overflow: hidden;
      border-radius: 50%;

      .avatar-text {
        font-size: 12px !important;
        line-height: 24px;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .author-name {
      font-size: 14px;
      color: #3b3939;
    }
  }
}

.date-cell {
  .admin-playlist-date {
    font-size: 13px;
    color: #6b7280;
  }
}

.numeric-cell {
  text-align: center;
  font-size: 14px;
  color: #4b5563;
}

.visibility-column {
  .status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;

    &.public {
      background: #ecfdf3;
      color: #065f46;
    }
    &.private {
      background: #fef2f2;
      color: #991b1b;
    }
    &.unlisted {
      background: #fef3c7;
      color: #92400e;
    }
  }
}

.control-button {
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;
  color: #636366;
  transition: all 0.2s;
  align-items: center;

  &:hover {
    color: #ef4444;
  }
}

.category-column {
  min-width: 120px;

  .category-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    background-color: #e0e0e0;
    color: #333;
    text-transform: capitalize;
  }
}

.category-header {
  min-width: 120px;
  cursor: pointer;

  &.sorted {
    font-weight: bold;

    &.asc::after {
      content: " ↑";
    }

    &.desc::after {
      content: " ↓";
    }
  }
}
