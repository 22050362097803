.channel {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  width: 90%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;

  &.item-card {
    gap: 60px;
    margin-left: 50px;
  }

  .channel-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    max-width: 35px;
    height: 35px;

    &.item-card {
      min-width: 80px;
      max-width: 80px;
      height: 80px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .channel-right {
    display: flex;
    flex-direction: column;
    // gap: 4px;
    font-size: 13.5px;
    width: 100%;

    .channel-first-last-name {
      color: #636366;
      line-height: 16px;
      word-wrap: break-word;
      max-width: calc(100% - 24px);
      align-items: center;
      flex: 1;
      font-weight: 500;
      &.item-card {
        font-weight: 700;
        color: black;
      }
    }

    .views-and-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #636366;
      font-weight: 400;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;

      .video-views {
        display: flex;
        padding-right: 8px;
        align-items: center;
        border-right: 1px solid #e5e5ea;
      }

      p {
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
