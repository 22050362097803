.description-tag {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  background-color: #f4f4f4;
  border-radius: 12px;

  .description {
    display: flex;
    flex-direction: column;

    .description-content {
      color: #636366;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      flex-direction: column;

      .explanation-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        .explanation-title {
          color: #3a3a3c;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      &.clipped {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.one-row {
        display: flex;
        flex-direction: row;
      }

      .description-text {
        width: calc(100% - 90px);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .description-see-more {
        margin-left: auto;
        color: #3a3a3c;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .see-more-less {
      margin-left: auto;
      color: #3a3a3c;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      padding-top: 8px;
    }

    .description-tag-tags {
      margin-top: 12px;
    }
  }
}
