.landing-page-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  // .ad-banner-signup {
  //   width: 100%;
  //   padding: 24px;
  //   gap: 24px;
  //   display: flex;
  //   flex-direction: row;
  // }

  @media (max-width: 992px) {
    .ad-banner-login {
      justify-content: center;
    }
  }

  .ad-banner-login {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 16px;

    .add-banner-login-video {
      padding: 20px;
      flex: 1;
      border-radius: 24px;
      border: 1px #c7c7cc solid;
      max-width: 700px;
    }

    .add-banner-login-banner {
      flex: 1;
    }
  }
}
