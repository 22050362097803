.admin-videos-wrapper {
  height: 100%;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: white;

  .table-header {
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1300px;

    .delete-selected {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      background-color: transparent;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: #fee2e2;
      }
    }

    .controls-group {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .table-scroll-container {
    overflow-x: auto;
    border: 1px solid #e5e7eb;
    border-radius: 8px;

    .videos-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #e5e7eb;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background: #f8f9fa;
        tr {
          th {
            background: #f8f9fa;
            font-weight: 600;
            cursor: pointer;
            user-select: none;
            position: sticky;
            top: 0;
            z-index: 10;
            padding: 1rem 1.5rem;
            text-align: left;
            border-bottom: 1px solid #e5e7eb;
            white-space: nowrap;
            transition: background-color 0.2s;

            &.Upload {
              text-align: center;
            }

            &:hover {
              background-color: #f3f4f6;
            }

            &.asc::after,
            &.desc::after {
              content: "";
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 0.5rem;
              vertical-align: middle;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
            }

            &.asc::after {
              border-bottom: 4px solid currentColor;
            }

            &.desc::after {
              border-top: 4px solid currentColor;
            }

            &.checkbox-column {
              text-align: center;
              width: 40px;

              input[type="checkbox"] {
                cursor: pointer;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }

      .numeric-header {
        text-align: center;
      }

      .numeric-cell {
        text-align: center;
        color: #4b5563;
        font-weight: 500;
        font-size: 14px;
      }

      .date-header,
      .date-cell {
        text-align: right;
        padding-right: 2rem;
      }

      tr {
        &:hover {
          background-color: #f9fafb;
        }

        &.selected {
          background-color: #f0f9ff;
        }

        td.checkbox-column {
          text-align: center;
          width: 40px;

          input[type="checkbox"] {
            cursor: pointer;
            width: 16px;
            height: 16px;
            accent-color: #35a7ff;
          }
        }
      }
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 48px;
  }

  .error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #ef4444;
    font-size: 14px;
    padding: 48px;
  }

  .table-controls {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    width: 100%;
    box-sizing: border-box;

    .search-field {
      position: relative;
      flex: 0 0 300px;

      input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s;

        &:focus {
          outline: none;
          border-color: #34a7fd;
          box-shadow: 0 0 0 2px rgba(52, 167, 253, 0.1);
        }
      }

      .search-indicator {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .delete-selected {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      background-color: #fff;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 0.875rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover:not(:disabled) {
        background-color: #fee2e2;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #f3f4f6;
      }
    }
  }
}

.pagination-controls {
  margin-top: auto;
  padding: 16px 24px;
  border-top: 1px solid #e5e7eb;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      padding: 8px 12px;
      border: 1px solid #e5e7eb;
      border-radius: 6px;
      background: #fff;
      color: #374151;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover:not(:disabled) {
        background: #f3f4f6;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .pagination-settings {
    display: flex;
    align-items: center;
    gap: 16px;

    select {
      padding: 6px 12px;
      border: 1px solid #e5e7eb;
      border-radius: 6px;
      background: #fff;
      font-size: 14px;
    }

    .total-rows {
      color: #6b7280;
      font-size: 14px;
    }
  }
}

.search-bar {
  flex: 1;
  margin-right: 16px;

  .search-input {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 300px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #34a7fd;
    }
  }
}

.sortable {
  cursor: pointer;
  user-select: none;
  transition: color 0.2s;

  &:hover {
    color: #34a7fd;
  }
}

.sort-button {
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #fff;
  color: #636366;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #34a7fd;
    color: #34a7fd;
  }
}

.search-bar {
  .search-input {
    width: 240px;
  }
}

.category-header {
  text-align: left;
  white-space: nowrap;

  &.sorted::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.5rem;
    vertical-align: middle;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  &.sorted.asc::after {
    border-bottom: 4px solid currentColor;
  }

  &.sorted.desc::after {
    border-top: 4px solid currentColor;
  }
}
