.card-thumbnail-preview {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 366px;
  align-items: center;
  cursor: pointer;

  .card-thumbnail-image {
    height: 40px;
    aspect-ratio: 1;
    background-color: #f2f4f7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-container {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .card-thumbnail-title {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .card-thumbnail-title-text {
      color: #48484a;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .card-thumbnail-title-type {
      color: #636366;
      font-size: 13.505px;
      font-weight: 600;
      line-height: 100%;
    }
  }
}
