.popup-container {
  -webkit-animation: fadein 0.2s ease-in-out;
  animation: fadein 0.2s ease-in-out;
}

.animate__close-popup-container {
  opacity: 0;
  -webkit-animation: fadeout 0.2s ease-in-out !important;
  animation: fadeout 0.2s ease-in-out !important;
}

.popup {
  -webkit-animation: open-popup 0.2s ease-in-out;
  animation: open-popup 0.2s ease-in-out;
}

.animate__close-popup {
  opacity: 0;
  -webkit-animation: close-popup 0.2s ease-in-out !important;
  animation: close-popup 0.2s ease-in-out !important;
}

@-webkit-keyframes open-popup {
  from {
    opacity: 0;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes open-popup {
  from {
    opacity: 0;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes close-popup {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.3);
  }
}

@keyframes close-popup {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

/* Animations for popup backgrounds */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
