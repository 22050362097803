//import variables
@import "../../styles/variables.scss";
.right-wrapper {
  width: calc(100vw - 350px);
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}
.Mui-selected {
  background-color: #35a7ff !important;
}
.video-add-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(58, 58, 60, 0.4);
  z-index: 10;
  cursor: auto;

  .video-add-form {
    width: 80vw;
    height: 80vh;
    background-color: #fff;
    border: 1px solid #e7e7e9;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
  .video-add-title {
    padding: 16px 24px 16px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5ea;
    color: #3a3a3c;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    .playlists-search {
      min-width: 336px;
    }
  }
  .video-add-cross-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    width: 24px;
    height: 24px;
  }
  .add-content {
    display: flex;
    padding: 16px 40px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 70vh;

    .video-add-tabs {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
    .video-list {
      height: auto;
      max-height: 60vh;
      padding-right: 20px;
      overflow: auto;
      margin-top: 24px;
      display: flex;
      gap: 15px;
      flex-grow: 1;
    }
    .no-results-text {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #636366;
    }
    .pagination {
      margin-top: 10px;
      align-self: center;
    }
  }
}

.search-tab-button {
  border: none;
  display: flex;
  padding: 6px 12px;
  border-radius: 500px;
  background-color: #f4f4f4;
  color: #48484a;
  font-size: 14px;
  font-weight: 500;
  min-width: max-content;
  cursor: pointer;
  &.active {
    color: white;
    font-weight: 600;
    background-color: #1a1a1c;
  }
  &.disabled {
    background-color: rgb(17, 15, 15);
    cursor: not-allowed;
  }
}
.username-filter-button {
  border-style: solid;
  border-color: #35a7ff;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 500px;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 500;
  min-width: max-content;
  cursor: pointer;
}
.close-button {
  height: 18px;
  margin-left: 3px;
}
.creator-line {
  height: 100px;
}
.thumbnail {
  background: url("../../../public/gradient.jpg") lightgray 50% / cover
    no-repeat;
  background-blend-mode: luminosity;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 30px;
  align-items: center;
  height: fit-content;
  padding: 0px 48px;
  min-height: 280px;
  width: 100%;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    margin-right: 10px;
    color: var(--Neutral-light-10, #fff);

    .title-text {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
    }
    .desc-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .image-container {
      min-width: 329px;
      margin-left: 24px;
    }
  }
}

.playlist-suggest-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  .playlist-suggest {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    margin-top: 24px;

    .description {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      .title-card {
        color: var(--Neutral-dark-800, #3a3a3c);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }
      .desc-card {
        display: flex;
        flex-direction: column;
        color: var(--Neutral-dark-500, #8e8e93);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
