@import "../../styles/variables.scss";

.sidebar-btn {
  min-height: 45px;
  min-width: 45px;
  padding: 4px;
  border: none;
  background-color: #f4f4f4;
  color: black;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease-in;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.sidebar-btn:hover {
  .tooltip-text {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.4s;
  }
}

.sidebar-btn:not(:hover) .tooltip-text {
  transition-delay: 0.2s;
  opacity: 0;
  visibility: hidden;
}

.more-dropdown {
  margin-top: 12px;
  border: 1px solid #e5e5ea;
  background-color: white;
  border-radius: 16px;
  display: flex;
  width: 240px;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 100%;
  left: calc(50% - 120px);
  z-index: 1;
  box-shadow: 0px 12px 24px 0px rgba(1, 12, 27, 0.1);

  .more-list {
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    .more-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px 8px;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      .more-list-left {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
          color: #3a3a3c;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
