.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .message {
    background-color: white;
    padding: 16px;
    padding-bottom: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    width: 360px;
    text-align: center;

    .cancel {
      margin-bottom: 4px;
      display: flex;
      flex-direction: flex-start;

      .cancel-icon {
        cursor: pointer;
        margin-left: auto;
        width: 24px;
        height: 24px;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .icon-div {
        .icon {
          width: 64px;
          height: 64px;
        }
      }

      .title {
        color: #48484a;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
      }

      .body-message {
        color: #48484a;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .alert-modal-buttons {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: 40px;
    }
  }
}
