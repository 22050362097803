@import "../../styles/variables.scss";

* {
  box-sizing: border-box;
}

.video-portrait {
  aspect-ratio: 9 / 16;
  height: 100% !important;
}

.video-portrait-embedded {
  aspect-ratio: 9 / 16;
  width: calc(min((100vh - 200px) * 9 / 16, 100%));
}

.video-landscape {
  aspect-ratio: 16 / 9;
  width: 100% !important;
}

.video-landscape-embedded {
  aspect-ratio: 16 / 9;
  width: 100% !important;
  max-width: 100% !important;
}

body {
  margin: 0;
}

.video-container {
  position: relative;
}

.vjs-control-bar {
  line-height: 1;
  border-radius: 0 0 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.4) !important;
  align-items: center;
  position: absolute;
  width: 100%;
  display: flex;
}

.video-js .vjs-progress-holder {
  min-height: 7px !important;
  .vjs-slider-bar {
    font-size: 21px !important;
  }

  .vjs-slider-bar::after {
    font-size: 30px !important;
  }
}

.vjs-progress-control {
  position: absolute !important;
  bottom: 20px;
  left: 0;
  min-width: 100% !important;
}

.vjs-custom-time-display {
  color: #fff;
  margin: 1em;
}

.vjs-current-time,
.vjs-duration,
.vjs-time-divider,
.vjs-remaining-time {
  display: none !important;
}

.vjs-playback-rate {
  margin-left: auto !important;
  transform: scale(0.67);
  display: flex !important;
}

.vjs-playback-speed-button {
  color: #fff;
  margin-left: auto !important;
}

.vjs-fullscreen-control {
  order: 1;
}

.vjs-icon-placeholder:before {
  margin: 0;
}

.vjs-volume-control {
  min-width: 50px;
  display: inline-block !important;
  transition: none !important;
}

.vjs-tech {
  border-radius: 1rem;
  overflow: hidden;
  background-color: white !important;
  box-shadow: 0 0 0 10px white;
}

.video-js .vjs-play-progress {
  background-color: #35a7ff !important;
}

.vjs-autoplay-button .autoplay-toggle {
  width: 28px;
  height: 12px;
  background-color: #c7c7cc;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.3s;
  margin-left: 4px;
}

.autoplay-toggle.on {
  background-color: #90cbf8;
}

.vjs-autoplay-button .autoplay-slider {
  width: 16px;
  height: 16px;
  background-color: #35a7ff !important;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: 16px;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoplay-icon {
  width: 0;
  height: 0;
  margin-left: 1.5px;
  border-left: 7px solid white;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.autoplay-icon.pause {
  border-left: 7px solid #35a7ff;
}

.autoplay-icon.pause::before {
  content: "";
  display: block;
  min-width: 2px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  left: 5px;
  top: 4px;
}

.autoplay-icon.pause::after {
  content: "";
  display: block;
  width: 2px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  top: 4px;
  right: 5.5px;
}

.vjs-poster img {
  border-radius: 1rem;
  overflow: hidden;
  background-color: white !important;
  box-shadow: 0 0 0 10px white;
}
