@import "../../styles/variables.scss";
.shell-component {
  width: calc(80vw - 80px);
  height: 100%;
  position: relative;
  padding: 0px 4px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;

  .video-part {
    max-width: calc(100% - 170px);
    flex-grow: 1;
  }

  .button-part {
    min-width: 140px;
    width: auto;
    display: flex;
    flex-direction: right;
    align-items: right;

    .button-container {
      margin-left: auto;
      height: auto;
    }
  }
}
