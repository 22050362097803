.sign-up-banner {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #35a7ff;

  .banner-text {
    color: white;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}
.cookie-banner {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  box-shadow: 0px 12px 24px 0px rgba(1, 12, 27, 0.5);
  border-top-left-radius: 20px 20px;
  border-top-right-radius: 20px 20px;
  min-height: 110px;

  .banner-text {
    font-size: 16px;
    font-weight: 500;
  }

  .cookie-button{
    min-width: 150px;
  }
}
