.tags-updated {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  overflow: hidden;

  .single-tag {
    border-radius: 12px;
    padding: 4px 10px;
    background-color: #e1ecf5;
    color: #35a7ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    flex-shrink: 0;
  }

  &.detail-mode {
    flex-wrap: wrap;
  }
}
