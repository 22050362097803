.upload-popup-form-background {
    position: fixed;
    top: 74px; 
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    background-color: rgba(58, 58, 60, 0.4);
    padding-top: 37px; 
    padding-bottom: 112px; 
    overflow-y: auto; 
    z-index: 1000; 
}