@import "../../styles/variables.scss";

.playlist-container {
  display: flex;
  height: 100%;

  .playlist-component {
    padding: 24px;
    width: 100%;
    min-width: 46vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
    overflow: scroll;

    @media (max-width: 1000px) {
      .playlist-header {
        flex-direction: column;
      }
    }
    .playlist-header {
      display: flex;
      gap: 20px;

      .playlist-thumbnail {
        max-width: 320px;
        max-height: 300px;
        min-width: 320px;
        border-radius: 12px 12px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        border-radius: 8px;
      }

      .header-side {
        width: 100%;

        .playlist-title {
          color: #3a3a3c;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* Max number of lines to display */
          overflow: hidden;
          overflow-wrap: break-word;
          word-break: break-word;
          white-space: normal;
        }

        .playlist-title-empty {
          color: #aeaeb2;
        }

        .name-input {
          margin-bottom: 20px;
          padding: 8px;
          border-radius: 4px;
          border: none;
          width: 100%;
        }

        .avatar {
          width: 24px;
          height: 24px;
        }

        .creator-name {
          color: var(--Neutral-dark-800, #3a3a3c);
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          margin: 20px 0px 16px 0px;
          display: flex;
          gap: 8px;
        }

        .stats-info {
          color: var(--Neutral-dark-600, #636366);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 16px;
          display: flex;
          gap: 12px;
          .video-views {
            border-right: 1px solid var(--Neutral-light-200, #e5e5ea);
            padding-right: 8px;
          }
        }
        .privacy-box {
          align-items: center;
          display: flex;
          gap: 8px;
          align-self: stretch;
          margin-top: 16px;
        }
        .privacy-text {
          color: var(--Neutral-dark-600, #636366);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        p {
          font-size: $tag-font-size;
          margin-bottom: 5px;
          padding: 0;
        }
      }
    }

    .playlist-component-buttons {
      display: flex;
      margin-left: auto;
      gap: 8px;
      margin-top: 10px;
    }
  }
}
