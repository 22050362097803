.stats-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;

  .stat-card {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .stat-title {
      color: #6b7280;
      font-size: 14px;
    }

    .stat-value {
      font-size: 24px;
      font-weight: 600;
      margin: 8px 0;
    }

    .stat-change {
      font-size: 14px;
      &.positive {
        color: #10b981;
      }
      &.negative {
        color: #ef4444;
      }
    }
  }
}
