.video-add-wrapper {
  .video-add-form {
    .video-preview-body {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
  .video-preview-header {
    display: flex;
    padding: 16px 24px 16px 16px;
    min-height: 70px;
    max-height: 70px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-light-200, #e5e5ea);
    background: var(--Neutral-light-10, #fff);
  }

  .video-add-cross-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    width: 24px;
    height: 24px;
  }
  .return-button {
    display: flex;
    width: 143px;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: white;
    color: var(--Neutral-dark-800, #3a3a3c);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
