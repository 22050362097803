.search-input-component {
  display: flex;
  flex-direction: column;
  color: #3a3a3c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  width: 100%;
  gap: 6px;

  .text-input-component-bottom {
    width: 100%;
    border-radius: 50px;
    border: 1px #c7c7cc solid;
    color: #3a3a3c;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 10px 16px;

    .ai-search-toggle {
      width: 40px;
      height: 25px;
      position: relative;
    }

    .search-icon {
      width: 18px;
      height: 18px;
    }

    .cancel-icon {
      width: 18px;
      height: 18px;

      &:hover {
        cursor: pointer;
      }
    }

    .text-input-component-input {
      width: 100%;
      border: none;

      color: #292e30;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &::placeholder {
        color: #8e8e93;
      }

      &:focus {
        outline: none;
        caret-color: #0d8eec;
        caret-shape: block;
      }
    }

    &:hover {
      border: 1px #3a3a3c solid;
    }

    .text-input-component-input::hover {
      border: 1px #3a3a3c solid;
    }
  }

  .focus {
    border: 1px #0d8eec solid !important;
    outline: none;
    caret-color: #0d8eec;
    caret-shape: block;
  }
}
