.playlist-preview-component {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 10%;
  height: 100%;
  padding-bottom: 0;

  .playlist-header {
    justify-content: space-between;
    width: 100%;
    display: flex;
    background-color: #fbfbfb;
    height: 10%;

    p {
      margin: 0;
    }

    .playlist-header-title {
      color: #3a3a3c;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      padding-left: 8px;
      display: "inline";
    }
  }

  .playlist-preview {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding-bottom: 25px;
    scroll-snap-type: y mandatory;
  }
}
