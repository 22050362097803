.create-playlist-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  border-bottom: 1px solid #e5e5ea;
  padding-bottom: 16px;

  .playlist-modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 6px;
    font-size: 14px;
    font-weight: 500;
    input {
      width: 100%;
      padding: 12px 16px;
      border: 1px solid #e5e5ea;
      border-radius: 8px;
      font-size: 14px;
      transition: 0.2s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid #35a7ff;
      }
    }
  }
  .playlist-modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 8px;
  }
}
