.thumbnail-edit-container {
  @media (max-width: 500px) {
    .video-thumbnail {
      width: min(100%, 336px);
    }
  }
  @media (min-width: 501px) {
    .video-thumbnail {
      width: 336px;
    }
  }

  .video-thumbnail {
    display: flex;
    align-items: center;
    aspect-ratio: 16/9;
  }
  .instruction {
    color: #8e8e93;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px;
  }
  .thumbnail-edit {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 5px;
  }
  .thumbnail-btn {
    color: #35a7ff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;

    &:hover {
      text-decoration: underline #35a7ff;
      cursor: pointer;
    }

    &.no-action {
      color: #8e8e93;
      cursor: default;
      text-decoration: none;
    }
  }
}
