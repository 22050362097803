.initial-heading {
  padding: 16px 24px;
  display: flex;
  font-size: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .initial-heading-button {
    margin-left: auto;
    text-decoration: none;
  }
}

.my-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;

  .table-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: auto;

    .video-items {
      min-width: 1300px;
      height: 100%;
      overflow-y: scroll;
    }
  }
  .loading-container-styling {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .table-headings-component {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px #e7e7e9 solid;
    border-bottom: 1px #e7e7e9 solid;
    color: #8e8e93;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    min-width: 1300px;

    .first-child {
      width: 516px;

      &.card-styling {
        width: 516px;
      }
    }

    .date-created {
      width: 150px;
    }

    .second-child {
      width: 270px;
    }
    .third-child {
      width: 135px;
    }
    .fourth-child {
      width: 156px;
    }
  }

  .video-item:hover {
    background-color: #f4f4f4;
  }
}

.my-content-item-component {
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  align-items: center;
  min-height: 90px;

  .video-stats {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    width: 270px;
    color: #636366;
    font-weight: 500;
    justify-content: space-between;

    .vertical-line {
      border-right: 1px #e5e5ea solid;
    }
  }

  .video-date-created {
    width: 150px;
    color: #48484a;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .video-status {
    width: 135px;

    .dropdown-privacy-list {
      position: relative;
    }
  }

  .video-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .control-icon {
      height: 36px;
      cursor: pointer;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
    }

    .control-icon-img {
      width: 24px;
      height: 24px;
    }
  }
}
