.dnd-sortable-tree_simple_wrapper {
  list-style: none;
  box-sizing: border-box;
  margin-bottom: 5px;
  max-width: 380px;
  height: 40px;
}

.dnd-sortable-tree_simple_tree-item {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 5px;
  color: #222;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* overflow: auto; */
  box-sizing: border-box;
}

.dnd-sortable-tree_simple_tree-item-chosen {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  background: var(--Neutral-light-50, #f4f4f4);
  justify-content: space-between;
}

.dnd-sortable-tree_simple_tree-item-creating {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  background: var(--Neutral-light-50, #f4f4f4);
  justify-content: space-between;
  pointer-events: none;
}

.dnd-sortable-tree_simple_tree-item-creating-empty {
  color: #aeaeb2;
}

.untitled {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--Neutral-light-50, #f4f4f4);
  justify-content: space-between;
  color: #aeaeb2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dnd-sortable-tree_simple_clone {
  display: inline-block;
  pointer-events: none;
  padding: 5px;
}

.dnd-sortable-tree_simple_clone > .dnd-sortable-tree_simple_tree-item {
  padding-top: 5px;
  padding-bottom: 5px;

  padding-right: 24px;
  border-radius: 4px;
  box-shadow: 0 15px 15px 0 rgba(34, 33, 81, 0.1);
}

.dnd-sortable-tree_simple_ghost {
  opacity: 0.5;
}

.dnd-sortable-tree_simple_disable-selection {
  user-select: none;
  -webkit-user-select: none;
}

.dnd-sortable-tree_simple_disable-interaction {
  pointer-events: none;
}

.dnd-sortable-tree_folder_tree-item-collapse_button {
  border: 0;
  width: 20px;
  align-self: stretch;
  transition: transform 250ms ease;
  background: url("data:image/svg+xml;utf8,<svg width='10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 41'><path d='M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z' /></svg>")
    no-repeat center;
}

.dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  transform: rotate(-90deg);
}
.dnd-sortable-tree_simple_handle {
  width: 20px;
  margin-left: 5px;
  margin-right: -5px;
  align-self: center;
  cursor: grab;
}

.dnd-sortable-tree_simple_tree-item-collapse_button {
  border: 0;
  width: 20px;
  background-color: white;
  align-self: center;
  transition: transform 250ms ease;
}

.dnd-sortable-tree_folder_simple-item-collapse_button-collapsed {
  align-self: center;
  border: 0;
  width: 20px;
  background: var(--Neutral-light-50, #f4f4f4);
  align-self: center;
  transition: transform 250ms ease;
}
