.hamburger-chapter-item {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  height: auto;
  padding: 8px 16px 8px 20px;
  height: 48px;
  cursor: pointer;

  .hamburger-chapter-item-icon {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%)
      hue-rotate(0deg) brightness(0%) contrast(100%);
  }

  .hamburger-chapter-item-text {
    color: #3a3a3c;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.hamburger-chapter-item:hover {
  background-color: #f4f4f4;
}

.selected {
  border-right: 4px #35a7ff solid;
  background-color: #35a7ff1a;
  .hamburger-chapter-item-text {
    font-weight: 700;
  }
}
