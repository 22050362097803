.users-table-wrapper {
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  font-family: "Inter", sans-serif;
  position: relative;
  z-index: 1;

  .table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;

    .search-field {
      position: relative;
      width: 300px;

      input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: border-color 0.2s;

        &:focus {
          outline: none;
          border-color: #34a7fd;
          box-shadow: 0 0 0 2px rgba(52, 167, 253, 0.1);
        }
      }

      .search-indicator {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    .delete-selected-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      background-color: #fff;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 0.875rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover:not(:disabled) {
        background-color: #fee2e2;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #f3f4f6;
      }

      .icon {
        font-size: 1rem;
      }
      .spinner-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .table-scroll-container {
    position: relative;
    width: 100%;
    overflow-x: auto;

    table.users-table {
      width: 100%;
      min-width: max-content;
      border-collapse: separate;
      border-spacing: 0;

      thead {
        tr {
          th {
            position: sticky;
            top: 0;
            z-index: 2;
            background: #f8f9fa;
            font-weight: 600;
            cursor: pointer;
            user-select: none;
            color: #1f2937;
            text-align: left;
            padding: 0.75rem 1.5rem;
            white-space: nowrap;
            border-bottom: 1px solid #e5e7eb;

            &:hover {
              background: #e9ecef;
            }

            &.sortable {
              cursor: pointer;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                opacity: 0.3;
              }

              &.sorted {
                color: #34a7fd;

                &.asc::after {
                  border-bottom: 4px solid #34a7fd;
                  opacity: 1;
                }

                &.desc::after {
                  border-top: 4px solid #34a7fd;
                  opacity: 1;
                }
              }
            }

            &.numeric-header {
              text-align: center;
              font-weight: 500;
            }
            &.date-header {
              text-align: right;
              font-weight: 500;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: #f8fafc;
          }
          &.selected {
            background-color: #f1fafe;
          }
          &.loading-row {
            opacity: 0.6;
            pointer-events: none;
          }

          td {
            padding: 0.75rem 1.5rem;
            white-space: nowrap;
            border-bottom: 1px solid #e5e7eb;
            font-size: 14px;
            color: #4b5563;

            &.numeric-cell {
              text-align: center;
              font-weight: 400;
            }
            &.date-cell {
              text-align: right;
              padding-right: 2rem;
              font-weight: 400;
            }
            &.checkbox-column {
              text-align: center;
              width: 40px;
            }

            .username-link {
              color: #34a7fd;
              text-decoration: none;
              font-weight: 500;

              &:hover {
                text-decoration: underline;
              }
            }

            .status-badge {
              display: inline-block;
              padding: 4px 8px;
              border-radius: 16px;
              font-size: 12px;
              font-weight: 500;

              &.active {
                background: #e3fcef;
                color: #006644;
              }
              &.inactive {
                background: #fee2e2;
                color: #721c24;
              }
            }
          }
        }
      }
    }
  }

  .loading-cell {
    text-align: center;
    padding: 2rem 0;
  }
}

.users-table {
  th,
  td {
    &.numeric-cell {
      text-align: center;
      font-weight: 400;
      color: #4b5563;
    }

    &.date-cell {
      text-align: right;
      padding-right: 2rem;
      font-weight: 400;
    }
  }

  .numeric-header {
    text-align: center;
  }
}

.loading-row {
  opacity: 0.6;
  pointer-events: none;
}
