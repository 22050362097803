.filter-list-component {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .category-filter-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 16px 24px;
    gap: 8px;
    margin: 0;
    overflow: auto;
    align-items: flex-start;
    scroll-snap-type: x mandatory;

    .filter-list-item {
      display: flex;
      padding: 6px 12px;
      border-radius: 4px;
      background-color: #f4f4f4;
      color: #48484a;
      font-size: 14px;
      font-weight: 500;
      min-width: max-content;
      cursor: pointer;
    }

    .selected-item {
      color: white;
      font-weight: 600;
      background-color: #35a7ff;
    }
  }
  .scroll-nav-button {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #c7c7cc;
    box-shadow: 0px 12px 24px 0px rgba(1, 12, 27, 0.1);
  }

  .prev-button {
    left: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  .next-button {
    right: 8px;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
