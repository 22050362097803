.search-modal-overlay {
  max-height: 80%;
  background-color: rgba(58, 58, 60, 0.4);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 74px;
  left: 0;
}

.search-modal {
  position: absolute;
  background-color: white;
  box-shadow: 0px 12px 24px rgba(1, 12, 27, 0.1);
  border-radius: 12px;
  overflow-x: hidden;
  border: 1px #e5e5ea solid;
  width: 100%;
  min-width: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  top: 48px;
  left: min(0px, calc(50% - 200px));
  cursor: default;

  .ai-search-cancel {
    display: flex;
    justify-content: space-between;
    color: #3a3a3c;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    .ai-search-headline {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .beta {
        color: #ff9e0d;
        color: #35a7ff;
        user-select: none;
      }
    }
  }

  .ai-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 65px;
    border-bottom: 1px solid #e5e5ea;
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 26px;
    margin-left: -16px;
    margin-right: -16px;

    .ai-section-message {
      color: #3a3a3c;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      .ai-section-message-bottom {
        color: #636366;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .ai-section-right {
      max-width: 130px;
    }
  }

  .search-results {
    overflow-y: scroll;
    max-height: 60vh;
    overflow-x: hidden;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .search-modal-title {
    color: #3a3a3c;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }

  .search-filter-list {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    margin: -16px;
  }
  .creator-line {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 35px;
    .channel {
      gap: 12px;
    }
    .channel-logo {
      display: flex;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      overflow: hidden;
      .avatar-text {
        font-size: 16px;
      }
    }
    .channel-first-last-name {
      color: #48484a;
      font-size: 14px;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
    }
  }

  .card-line {
    padding: 4px 0px;
  }
}
