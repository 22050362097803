.admin-cards-wrapper {
  height: 100%;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: white;

  .table-controls {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    width: 100%;
    box-sizing: border-box;

    .search-field {
      position: relative;
      flex: 0 0 300px;

      input {
        width: 100%;
        padding: 0.75rem 1rem;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s;

        &:focus {
          outline: none;
          border-color: #34a7fd;
          box-shadow: 0 0 0 2px rgba(52, 167, 253, 0.1);
        }
      }

      .search-indicator {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .delete-selected {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      background-color: #fff;
      border: 1px solid #ef4444;
      color: #ef4444;
      border-radius: 6px;
      font-size: 0.875rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover:not(:disabled) {
        background-color: #fee2e2;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #f3f4f6;
      }
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .table-scroll-container {
      overflow-x: auto;
      border: 1px solid #e5e7eb;
      border-radius: 8px;

      .admin-cards-table {
        width: 100%;
        border-collapse: collapse;

        /* Sticky thead so column headers remain visible */
        thead {
          position: sticky;
          top: 0;
          z-index: 1;
          background: #f8f9fa;

          tr {
            th {
              background: #f8f9fa;
              font-weight: 600;
              cursor: pointer;
              user-select: none;
              position: sticky;
              top: 0;
              z-index: 10;
              padding: 1rem 1.5rem;
              text-align: left;
              border-bottom: 1px solid #e5e7eb;
              white-space: nowrap;
              transition: background-color 0.2s;

              &:hover {
                background-color: #f3f4f6;
              }

              &.checkbox-header {
                text-align: center;
                width: 40px;

                input[type="checkbox"] {
                  cursor: pointer;
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        th,
        td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #e5e7eb;
        }

        .numeric-header {
          text-align: center;
        }

        .numeric-cell {
          text-align: center;
          color: #4b5563;
          font-weight: 500;
          font-size: 14px;
        }

        .date-header,
        .date-cell {
          text-align: left;
          padding-right: 2rem;
        }

        tr {
          &:hover {
            background-color: #f9fafb;
          }

          &.selected {
            background-color: #f0f9ff;
          }

          td.checkbox-column {
            text-align: center;
            width: 40px;

            input[type="checkbox"] {
              cursor: pointer;
              width: 16px;
              height: 16px;
              accent-color: #35a7ff; /* for a blue check in some browsers */
            }
          }
        }

        .loading-cell,
        .no-results {
          text-align: center;
          padding: 40px 0;
          color: #6b7280;
        }
      }
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 48px;
  }

  .error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #ef4444;
    font-size: 14px;
    padding: 48px;
  }

  .sortable {
    transition: color 0.2s;

    &:hover {
      color: #34a7fd;
    }

    &.sorted {
      color: #34a7fd;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }
  }

  .type-header {
    &.sorted {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }

      &.asc::after {
        border-bottom: 5px solid #34a7fd;
      }

      &.desc::after {
        border-top: 5px solid #34a7fd;
      }
    }
  }
}
