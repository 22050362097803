.edit-video-page {
  @media (orientation: portrait) {
    .upload-popup-form-body {
      flex-direction: column;
      .upload-popup-form-body-right {
        border-left: 0px;
        border-top: 1px solid #e7e7e9;
      }
    }
  }

  @media (orientation: landscape) {
    .upload-popup-form-body {
      .upload-popup-form-body-right {
        border-left: 1px solid #e7e7e9;
      }
    }
  }

  .upload-popup-form-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    align-items: stretch;
    min-height: calc(100vh - 80px);
    height: 100%;

    //for uploading a-b steps
    .upload-popup-form-body-left {
      padding: 24px 24px;
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 20px;

      // for editing details c-d steps
      .upload-popup-form-body-header {
        display: flex;
        justify-content: row;
        gap: 12px;
        color: #2d3648;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        img {
          cursor: pointer;
        }
      }

      @media (max-width: 600px) {
        .upload-popup-form-preview {
          flex-direction: column;
        }
      }

      .upload-popup-form-preview {
        display: flex;
        gap: 20px;
        .upload-popup-form-preview-right {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;

          .upload-popup-form-preview-right-title {
            color: #aeaeb2;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;

            &.has-title {
              color: #3a3a3c;
            }
          }

          .upload-popup-form-preview-right-description {
            color: #aeaeb2;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            overflow: hidden;
            display: block;
            white-space: pre-wrap;
            text-overflow: ellipsis;

            &.has-description {
              color: #48484a;
            }
          }

          .upload-popup-form-preview-right-tag {
            color: #aeaeb2;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            &.has-tag {
              color: #35a7ff;
            }
          }

          .upload-popup-form-preview-right-divider {
            border: 1px #e5e5ea solid;
          }

          .upload-popup-form-preview-right-visibility {
            display: flex;
            align-items: center;

            .upload-popup-form-preview-right-visibility-word {
              margin-right: 12px;
              color: #636366;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }
      }

      .upload-popup-form-body-comments {
        display: flex;
        justify-content: space-between;
        color: #3a3a3c;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      // for uploading a-b steps
      .upload-popup-form-body-insert {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .upload-popup-form-body-terms {
        padding: 24px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .upload-popup-form-body-term {
          color: #636366;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;

          .upload-terms {
            color: #35a7ff;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }

      .upload-bottom-component {
        display: flex;
        align-items: right;
        justify-content: flex-end;
        gap: 8px;
      }
    }

    .upload-popup-form-body-right {
      #tips {
        color: #2d3648;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-left: 1px solid #e7e7e9;
      padding: 24px 24px;
      flex: 1;
      gap: 24px;

      .upload-popup-form-body-right-title {
        color: #3a3a3c;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }

      .video-stats {
        display: flex;
        justify-content: row;
        gap: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        color: #636366;
        font-weight: 500;

        .vertical-line {
          border-right: 1px #e5e5ea solid;
        }
      }

      .upload-popup-form-hori-line {
        border-top: 1px solid #e5e5ea;
        width: 100%;
      }

      .stat-block {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .stat-block-headline {
          color: #8e8e93;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        .stat-block-description {
          color: #48484a;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

          a {
            color: #35a7ff;
          }
        }
      }
    }
  }
}
